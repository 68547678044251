import { v4 as uuidv4 } from "uuid";
export const MAX_BACKGROUND_IMAGE_SIZE = 206993; // 200kb

export const WARNING_MESSAGE =
  "Warning: Uploaded image size exceeds 200KB. This may cause non-persistent settings.";
export const FONTS = [
  { id: 0, name: "Default" },
  { id: 6, name: "DinMedium" },
  { id: 7, name: "DINNextLTPro-Heavy" },
  { id: 8, name: "DINNextLTPro-Regular" },
  { id: 9, name: "EndzoneSansCond-Bold" },
  { id: 1, name: "Montserrat" },
  { id: 2, name: "Alegreya" },
  { id: 3, name: "Nunito" },
  { id: 4, name: "Roboto" },
  { id: 5, name: "Poppins" },
];
export const DEFAULT_SETTINGS_CAROUSEL = {
  autoPlay: true,
  showArrows: true,
  carouselSpeed: 1500,
  backgroundColor: "rgba(0, 0, 0, 0)",
  borderWidth: 2,
  borderColor: "#fff",
  roundedCorners: true,
  backgroundImage: null,
};
export const DEFAULT_SETTINGS_SLIDE_SHOW = {
  backgroundColor: "rgba(0, 0, 0, 0)",
  randomTransitions: true,
  selectedTransition: "fade",
  borderWidth: 2,
  borderColor: "#fff",
  roundedCorners: true,
  backgroundImage: null,
};
export const DEFAULT_SETTINGS_SCROLLING_MASONRY = {
  backgroundColor: "rgba(0, 0, 0, 0)",
  scrollSpeed: "slow",
  gap: 20,
  borderWidth: 4,
  borderColor: "#fff",
  roundedCorners: true,
  backgroundImage: null,
  numberOfColumns: 4,
};
export const DEFAULT_SETTINGS_FIXED_MASONRY = {
  selectedLayout: 10,
  backgroundColor: "rgba(0, 0, 0, 0)",
  flipDelay: 3, // Seconds
  gap: 8,
  borderWidth: 2,
  borderColor: "#fff",
  roundedCorners: true,
  backgroundImage: null,
  showLikes: true,
  showName: true,
  fontSize: 10,
  font: FONTS[0].name,
  fontColor: "#fff",
};
export const DEFAULT_SETTINGS_SOCIAL_GRID = {
  selectedGridLayoutOption: 1,
  numberOfColumns: 8,
  numberOfRows: 1,
  cardOrientation: 1,
  backgroundColor: "rgba(0, 0, 0, 0)",
  flipDelay: 3, // Seconds
  gap: 8,
  borderWidth: 3,
  borderColor: "#fff",
  roundedCorners: true,
  backgroundImage: null,
  showLikes: true,
  showName: true,
  fontSize: 10,
  font: FONTS[0].name,
  fontColor: "#fff",
};
export const QUOTE_CARDS = [
  { id: 1, color: "#88A28D", bgImage: null, contentColor: "#fff" },
  { id: 2, color: "#977F6D", bgImage: null, contentColor: "#fff" },
  { id: 3, color: "#C2491D", bgImage: null, contentColor: "#fff" },
  { id: 4, color: "#B62429", bgImage: null, contentColor: "#fff" },
  { id: 5, color: "#bbacaf", bgImage: null, contentColor: "#fff" },
  { id: 6, color: "#b62429", bgImage: null, contentColor: "#fff" },
];

export const DEFAULT_SETTINGS_SOCIAL_QUOTES_GRID = {
  selectedGridLayoutOption: 169,
  numberOfColumns: 2,
  numberOfRows: 2,
  backgroundColor: "rgba(0, 0, 0, 0)",
  flipDelay: 3, // Seconds
  gap: 32,
  borderWidth: 3,
  roundedCorners: true,
  backgroundImage: null,
  quoteCards: QUOTE_CARDS,
  mediaItemRotateBy: -7,
  mediaItemX: 0,
  setAlternateCard: false,
  mediaCardScale: 100,
  useCommonCardStyle: false,
  showQuoteIcon: true,
  quoteFontSize: 20,
  font: FONTS[0].name,
};
export const DEFAULT_SETTINGS_GAMES_OUTPUT = {
  headingFontSize: 40,
  optionFontSize: 24,
  mediaItemX: 0,
  alternatePosition: false,
  borderWidth: 2,
  borderColor: "#fff",
  fontColor: "#fff",
  mediaCardScale: 100,
  position: "left",
  font: FONTS[0].name,
};

export const DEFAULT_SETTINGS_SOCIAL_QUOTES = {
  quoteFontSize: 23,
  backgroundColor: "rgba(0, 0, 0, 0)",
  backgroundImage: null,
  slideDelay: 2,
  quoteCards: QUOTE_CARDS,
  mediaItemRotateBy: -7,
  mediaItemX: 0,
  setAlternateCard: false,
  mediaCardScale: 100,
  useCommonCardStyle: false,
  showQuoteIcon: true,
  font: FONTS[0].name,
};
export const SOCIAL_DEFAULT_SETTING_OBJECT_POINTERS = {
  uvFixedMasonrySettings: DEFAULT_SETTINGS_FIXED_MASONRY,
  uvScrollingMasonrySettings: DEFAULT_SETTINGS_SCROLLING_MASONRY,
  uvSlideShowSettings: DEFAULT_SETTINGS_SLIDE_SHOW,
  uvCarouselSettings: DEFAULT_SETTINGS_CAROUSEL,
  uvGamesOutputSettings: DEFAULT_SETTINGS_GAMES_OUTPUT,
  uvGridLayoutSettings: DEFAULT_SETTINGS_SOCIAL_GRID,
  uvSocialQuotesSettings: DEFAULT_SETTINGS_SOCIAL_QUOTES,
  uvQuotesGridLayoutSettings: DEFAULT_SETTINGS_SOCIAL_QUOTES_GRID,
};

export const VARIANT_LOCALSTORAGE_KEYS = {
  fixedMasonry: "uvFixedMasonrySettings",
  scrollingMasonry: "uvScrollingMasonrySettings",
  slideShow: "uvSlideShowSettings",
  carousel: "uvCarouselSettings",
  gamesOutput: "uvGamesOutputSettings",
  gridLayout: "uvGridLayoutSettings",
  socialQuotes: "uvSocialQuotesSettings",
  quotesGridLayout: "uvQuotesGridLayoutSettings",
};

export const LAYOUT_ORIENTATION_MAPPING = () => {
  const mappings = [
    ["card-4-0", "landscape"],
    ["card-4-1", "portrait"],
    ["card-4-2", "portrait"],
    ["card-4-3", "portrait"],
    ["card-7-0", "portrait"],
    ["card-7-1", "portrait"],
    ["card-7-2", "landscape"],
    ["card-7-3", "portrait"],
    ["card-7-4", "portrait"],
    ["card-7-5", "portrait"],
    ["card-7-6", "portrait"],
    ["card-10-0", "landscape"],
    ["card-10-1", "portrait"],
    ["card-10-2", "portrait"],
    ["card-10-3", "landscape"],
    ["card-10-4", "portrait"],
    ["card-10-5", "portrait"],
    ["card-10-6", "portrait"],
    ["card-10-7", "portrait"],
    ["card-10-8", "portrait"],
    ["card-10-9", "portrait"],
  ];
  return new Map(mappings);
};

export const FIXED_MASONRY_LAYOUTS = [
  { displayValue: "4:3", value: 4 },
  { displayValue: "16:9", value: 7 },
  { displayValue: "16:9 Extended", value: 10 },
];

export const SOCIAL_GRID_LAYOUTS = [
  { displayValue: "16:9", value: 169 },
  { displayValue: "Row Layout", value: 1 },
  { displayValue: "Column Layout", value: 2 },
];

export const RESPONSIVE_GRID_MULTIPLIER = 8;

export const getDefaultQuoteCardData = () => ({
  _id: `dummy-${uuidv4()}`,
  comment: "",
  createdAt: 0,
  likeCount: 0,
  mediaFileUrl: "",
  mimeType: "image/png",
  showOnQuotesFeed: true,
  userId: "Name",
  orientation: "portrait",
  width: 437,
  height: 478,
  cardSettings: {
    id: 1,
    color: "#000",
    bgImage: null,
    contentColor: "#fff",
  },
});

export const storageKeyForLightShowBrightness = "light_show_brightness_toggle";

const widthHeight = { w: 2 * RESPONSIVE_GRID_MULTIPLIER, h: 5 };
const layoutArray = [
  { i: "0", x: 3, y: 0, w: 2 * RESPONSIVE_GRID_MULTIPLIER, h: 6 },
  { i: "1", x: 3, y: 3, ...widthHeight },
  { i: "2", x: 3, y: 5, ...widthHeight },
  { i: "3", x: 3, y: 7, ...widthHeight },
  { i: "4", x: 3, y: 9, ...widthHeight },
  { i: "5", x: 3, y: 11, ...widthHeight },
  { i: "6", x: 3, y: 13, ...widthHeight },
  { i: "7", x: 3, y: 15, ...widthHeight },
  { i: "8", x: 3, y: 17, ...widthHeight },
  { i: "9", x: 3, y: 19, ...widthHeight },
  { i: "10", x: 3, y: 21, ...widthHeight },
  { i: "11", x: 3, y: 23, ...widthHeight },
  { i: "12", x: 3, y: 25, ...widthHeight },
  { i: "13", x: 3, y: 27, ...widthHeight },
  { i: "14", x: 3, y: 29, ...widthHeight },
];

export const defaultUvGridLayouts = {
  lg: layoutArray,
  md: layoutArray,
  sm: layoutArray,
  xs: layoutArray,
  xxs: layoutArray,
};
