import React, { useEffect, useState, useCallback, useRef } from "react";
import {
  CircleF,
  GoogleMap,
  InfoWindow,
  MarkerF,
  useJsApiLoader,
} from "@react-google-maps/api";
import GoogleMapsSearchBox from "./GoogleMapsSearchBox";

const containerStyle = {
  width: "100%",
  height: "100%",
};

const sanDiego = {
  lat: 32.71417,
  lng: -117.15843,
};

export default function GoogleMapComponent({
  initialLocation,
  sliderRadius,
  onChangeLatLng,
}) {
  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: "AIzaSyCMSDz67I_529-1zB4GD8MdHBBMpXObnCA",
    libraries: ["places"],
  });

  const [map, setMap] = useState(null);
  const [radius, setRadius] = useState(sliderRadius || 300);
  const [markerPosition, setMarkerPosition] = useState(sanDiego);
  const [showingInfoWindow, setShowingInfoWindow] = useState(false);

  useEffect(() => {
    setRadius(sliderRadius);
  }, [sliderRadius]);

  const onLoad = useCallback(function callback(map) {
    map.setMapTypeId("hybrid");
    if (initialLocation && initialLocation.length === 2) {
      const [lon, lat] = initialLocation;
      updateMarker(lat, lon);
    } else {
      navigator.geolocation.getCurrentPosition((position) => {
        updateMarker(position.coords.latitude, position.coords.longitude);
      });
    }
    setMap(map);
  }, []);

  const onUnmount = useCallback(function callback(map) {
    setMap(null);
  }, []);

  const onMarkerClick = () => {
    setShowingInfoWindow(true);
  };

  const geocoder = useRef(null);
  const updateMarker = (lat, long) => {
    // let pos = { lat: lat, lng: long };
    // setMarkerPosition(pos);
    // onChangeLatLng(pos);
    if (!geocoder.current) {
      geocoder.current = new window.google.maps.Geocoder();
    }
    geocoder.current.geocode(
      {
        location: {
          lat: lat,
          lng: long,
        },
      },
      (results, status) => {
        if (status === "OK" && results[0]) {
          // setAddress(results[0].formatted_address);
          console.log(results[0]);
          let pos = {
            lat: lat,
            lng: long,
            address: results[0].formatted_address,
          };
          setMarkerPosition(pos);
          onChangeLatLng(pos);
        } else {
          console.error("Geocoder failed due to: " + status);
        }
      }
    );
  };

  const onClose = () => {
    if (showingInfoWindow) {
      setShowingInfoWindow(false);
    }
  };

  return isLoaded ? (
    <GoogleMap
      mapContainerStyle={containerStyle}
      center={markerPosition}
      zoom={15}
      onLoad={onLoad}
      onUnmount={onUnmount}
      onClick={(loc) => updateMarker(loc.latLng.lat(), loc.latLng.lng())}
    >
      <GoogleMapsSearchBox
        style={{
          position: "absolute",
          top: 10,
          left: "50%",
          width: "37%", // Adjust the width as desired
        }}
        onPlacesChanged={(place) => {
          const lat = place.geometry.location.lat();
          const long = place.geometry.location.lng();
          updateMarker(lat, long);
        }}
      />

      {/* Child components, such as markers, info windows, etc. */}
      <MarkerF
        position={markerPosition}
        onClick={onMarkerClick}
        title={
          markerPosition && markerPosition.address
            ? markerPosition.address
            : markerPosition.lat + ", " + markerPosition.lng
        }
      >
        {showingInfoWindow && (
          <InfoWindow position={markerPosition}>
            <h4>
              {markerPosition && markerPosition.address
                ? markerPosition.address
                : markerPosition.lat + ", " + markerPosition.lng}
            </h4>
          </InfoWindow>
        )}
      </MarkerF>

      <CircleF
        radius={radius}
        center={markerPosition}
        options={{
          strokeColor: "green",
          strokeOpacity: 0.5,
          strokeWeight: 2,
          fillColor: "#C3FC49",
          fillOpacity: 0.3,
        }}
      />
    </GoogleMap>
  ) : (
    <></>
  );
}
