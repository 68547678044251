import { useState, useRef, useEffect } from "react";
import Loader from "../../components/Loader/Loader";
import { Grid } from "@mui/material";
import InfiniteLoaderGrid from "../Social/SocialOperator/InfininteLoaderGrid/InfiniteLoaderGrid";
import { reviewMedia } from "../Social/SocialOperator/networkCallsSocial";
import { uvenuFetcher } from "../../utils/uvenu-fetcher";
import { URLS } from "../../constants";
import { mergeAndDeduplicate } from "../../utils/utility";
const UnApproved = ({ eventId }) => {
  const loggedInUserObj = JSON.parse(localStorage.getItem("currentUser"));
  const [loading, setLoading] = useState(false);
  const [samePageTrigger, setSamePageTrigger] = useState(false);
  const [hasNoMore, setHasNoMore] = useState(false);
  const perPage = 10;
  const [pageNo, setPageNo] = useState(0);
  const [displayedMediaFiles, setDisplayedMediaFiles] = useState([]);
  const reviewedItem = useRef(null);
  const [onlyImages, setOnlyImages] = useState(false);
  const [onlyVideos, setOnlyVideos] = useState(false);
  const [newestFirst, setNewestFirst] = useState(true);
  const [searchText, setSearchText] = useState("");
  const [mediaFilesUnReviewed, setMediaFilesUnReviewed] = useState([]);
  const loadMoreItems = (startIndex) => {
    if (hasNoMore) {
      setSamePageTrigger(true);
      return;
    }
    let newPageNo = Math.floor(startIndex / perPage);
    setPageNo(newPageNo);
  };
  const review_Media = async (
    loggedInUserObj,
    mediaId,
    review,
    username,
    description,
    showOnQuotesChecked,
    metadata
  ) => {
    reviewedItem.current = mediaId;
    try {
      await reviewMedia(
        loggedInUserObj,
        mediaId,
        review,
        username,
        description,
        showOnQuotesChecked,
        review && metadata.width && metadata.height ? metadata : undefined
      );
      if (pageNo < 0) setPageNo(0);
      refreshData();
    } catch {}
  };
  const refreshData = () => {
    loadUnreviewedMediaData();
  };
  async function loadUnreviewedMediaData() {
    setLoading(true);
    let mimeType = null;
    if (onlyImages) mimeType = "image";
    if (onlyVideos) mimeType = "video";
    const unreviewedResponse = await uvenuFetcher({
      url: URLS.GET_UNREVIEWED_MEDIA(
        eventId,
        pageNo,
        perPage,
        mimeType,
        newestFirst ? "desc" : "asc",
        searchText.length > 0 ? searchText : null
      ),
      headers: {
        token: `${loggedInUserObj.token}`,
      },
      method: "GET",
    });

    if (
      unreviewedResponse.json &&
      unreviewedResponse.json.mediaFiles.length === 0 &&
      pageNo === 0
    ) {
      setHasNoMore(true);
      setLoading(false);
      return;
    }
    if (
      unreviewedResponse.json &&
      unreviewedResponse.json.mediaFiles.length < perPage
    ) {
      setHasNoMore(true);
    } else {
      setHasNoMore(false);
    }
    setMediaFilesUnReviewed(
      mergeAndDeduplicate(
        mediaFilesUnReviewed,
        unreviewedResponse.json.mediaFiles
      )
    );
    setLoading(false);
  }

  useEffect(() => {
    setLoading(true);
    refreshData();
  }, [pageNo, samePageTrigger]);
  useEffect(() => {
    setDisplayedMediaFiles(mediaFilesUnReviewed);
    if (mediaFilesUnReviewed.length === 0) {
      refreshData();
    }
  }, [mediaFilesUnReviewed]);
  return (
    <div className="mediaSection">
      {loading && (
        <div className="soOverlay">
          <Loader />
        </div>
      )}
      <div className="reviewGridContainer" spacing={1}>
        <InfiniteLoaderGrid
          loadMoreItems={loadMoreItems}
          items={displayedMediaFiles}
          hasNextPage={!hasNoMore}
          activeTab={0}
          handleReviewMedia={review_Media}
          pageSize={perPage}
          rowHeight={500}
          columnCount={3}
        />
      </div>
    </div>
  );
};
export default UnApproved;
