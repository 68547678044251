import { memo } from "react";
import { CDN_URLS } from "../../constants";
import LightShowEffects from "./LightShowEffects";
import Logo from "./Logo";
import { COLORS } from "../../utils/colors";
const LightShowPreview = memo(
  ({
    pulse,
    effect,
    color,
    color2,
    color1HoldTime,
    color2HoldTime,
    animationDuration,
    mediaForAudienceScreen,
    animationSpeed,
    holdDuration,
    repeatDelay,
    isAnimateLogo,
  }) => {
    return (
      <div
        className="phoneDiv"
        style={{ position: "relative", height: "100%" }}
      >
        <div
          className="phoneDiv"
          style={{
            background: `url("${CDN_URLS["MOBILE_FRAME"]}") no-repeat`,
            backgroundPosition: "center",
            backgroundSize: "cover",
            position: "absolute",
            zIndex: 2,
            inset: 0,
          }}
        ></div>
        <div
          className="phoneDiv"
          style={{
            position: "absolute",
            inset: "2%",
            borderRadius: "10%",
            overflow: "hidden",
            zIndex: 1,
          }}
          id="light-show-output"
        >
          {pulse ? (
            <LightShowEffects
              effect={effect}
              color={color}
              color2={color2}
              color1HoldTime={color1HoldTime}
              color2HoldTime={color2HoldTime}
              animationDuration={animationDuration}
            />
          ) : null}

          {mediaForAudienceScreen ? (
            <div className="logoContainerInPreview">
              <Logo
                mediaForAudienceScreen={mediaForAudienceScreen}
                animationSpeed={animationSpeed}
                holdDuration={holdDuration}
                repeatDelay={repeatDelay}
                isAnimateLogo={isAnimateLogo}
              />
            </div>
          ) : null}
        </div>
        <div
          className="phoneDiv"
          style={{
            position: "absolute",
            inset: "2%",
            borderRadius: "10%",
            overflow: "hidden",
            background: COLORS.lightShowBaseBG,
          }}
        ></div>
      </div>
    );
  }
);
export default LightShowPreview;
