import { dataURItoBlob } from "./mediaFunctions";

export const downloadMedia = (url, filename) => {
  fetch(url)
    .then((response) => response.blob())
    .then((blob) => {
      const href = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = href;
      link.setAttribute("download", "mediaFile-" + filename);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    })
    .catch((error) => {
      console.error("Error fetching the file for download:", error);
    });
};

export const isIframeWindow = () => window.self !== window.top;

const getUserSpecificKey = (key) => {
  let newKey = key;
  const currentUser = localStorage.getItem("currentUser");

  if (currentUser) {
    const user = JSON.parse(currentUser);
    if (user.email) {
      newKey = `${user.email}-${key}`;
    }
  }
  if (isIframeWindow() && window.name) {
    newKey = `${window.name}-${newKey}`;
  }
  return newKey;
};
export const getItemFromLocalStorage = (rawKey) => {
  const key = getUserSpecificKey(rawKey);

  let item = null;
  if (localStorage) {
    item = localStorage.getItem(key);
    if (typeof item !== "string") return item;
    if (item === "undefined") return undefined;
    if (item === "null") return null;
    // Check for numbers and floats
    if (/^'-?\d{1,}?\.?\d{1,}'$/.test(item)) return Number(item);
    // Check for serialized objects and arrays
    if (item[0] === "{" || item[0] === "[") return JSON.parse(item);
  }

  return item;
};
export const saveItemToLocalStorage = (rawKey, value) => {
  if (localStorage) {
    try {
      const key = getUserSpecificKey(rawKey);

      if (typeof key !== "string") {
        throw new TypeError(
          `localStorage: Key must be a string. (reading '${key}')`
        );
      }

      if (typeof value === "object") {
        value = JSON.stringify(value);
      }

      localStorage.setItem(key, value);
    } catch (e) {
      console.error("Error saving to local storage:", e.message);
    }
  }
};
export const getItemFromSessionStorage = (rawKey) => {
  const key = getUserSpecificKey(rawKey);

  let item = null;
  if (sessionStorage) {
    item = sessionStorage.getItem(key);
    if (typeof item !== "string") return item;
    if (item === "undefined") return undefined;
    if (item === "null") return null;
    // Check for numbers and floats
    if (/^'-?\d{1,}?\.?\d{1,}'$/.test(item)) return Number(item);
    // Check for serialized objects and arrays
    if (item[0] === "{" || item[0] === "[") return JSON.parse(item);
  }
  return item;
};
export const saveItemToSessionStorage = (rawKey, value) => {
  if (sessionStorage) {
    try {
      const key = getUserSpecificKey(rawKey);

      if (typeof key !== "string") {
        throw new TypeError(
          `localStorage: Key must be a string. (reading '${key}')`
        );
      }

      if (typeof value === "object") {
        value = JSON.stringify(value);
      }

      sessionStorage.setItem(key, value);
    } catch (e) {
      console.error("Error saving to local storage:", e.message);
    }
  }
};

export const debounce = (func, delay) => {
  let debounceTimer;
  return function () {
    const context = this;
    const args = arguments;
    clearTimeout(debounceTimer);
    debounceTimer = setTimeout(() => func.apply(context, args), delay);
  };
};
export const throttle = (fn, wait) => {
  let inThrottle, lastFn, lastTime;
  return function () {
    const context = this,
      args = arguments;
    if (!inThrottle) {
      fn.apply(context, args);
      lastTime = Date.now();
      inThrottle = true;
    } else {
      clearTimeout(lastFn);
      lastFn = setTimeout(function () {
        if (Date.now() - lastTime >= wait) {
          fn.apply(context, args);
          lastTime = Date.now();
        }
      }, Math.max(wait - (Date.now() - lastTime), 0));
    }
  };
};

export const mergeAndDeduplicate = (arr1, arr2, uniqueKey = "_id") => {
  return Array.from(
    [...arr1, ...arr2]
      .reduce((m, o) => m.set(o[uniqueKey], o), new Map())
      .values()
  );
};

export const formatHyperlink = (url) => {
  if (url.includes("http://") || url.includes("https://")) {
    return url;
  } else {
    return "http://" + url;
  }
};

export const RGBToHex = (rgb) => {
  if (rgb.indexOf("rgb") === -1) {
    return rgb;
  }
  // Choose correct separator
  let sep = rgb.indexOf(",") > -1 ? "," : " ";
  // Turn "rgb(r,g,b)" into [r,g,b]
  rgb = rgb.substr(4).split(")")[0].split(sep);

  let r = (+rgb[0]).toString(16),
    g = (+rgb[1]).toString(16),
    b = (+rgb[2]).toString(16);

  if (r.length == 1) r = "0" + r;
  if (g.length == 1) g = "0" + g;
  if (b.length == 1) b = "0" + b;

  return "#" + r + g + b;
};

export const isValidEmail = (email) => {
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return emailRegex.test(email);
};

export const postMessageToSuperScreen = (payload) => {
  const message = {
    dataFromIframe: payload,
  };
  const targetWindow = window.top;
  targetWindow.postMessage(message);
};

export const isVideoOrImage = (url) => {
  // Get the file extension from the URL
  const extension = url.split(".").pop().toLowerCase();

  // List of video extensions (add more if needed)
  const videoExtensions = ["mp4", "avi", "mov", "wmv", "flv", "mkv", "webm"];

  // List of image extensions (add more if needed)
  const imageExtensions = ["jpg", "jpeg", "png", "gif", "bmp", "svg"];

  // Check if the extension is in the video or image extensions list
  if (videoExtensions.includes(extension)) {
    return "video";
  } else if (imageExtensions.includes(extension)) {
    return "image";
  } else {
    return "unknown";
  }
};

export const getRandomColor = () => {
  const letters = "0123456789ABCDEF";
  let color = "#";
  for (let i = 0; i < 6; i++) {
    color += letters[Math.floor(Math.random() * 16)];
  }
  return color;
};
const IOS_SUPPORTED_MIMETYPE = "video/mp4;codecs=avc1,mp4a";
export const isIosTypeSupported = () =>
  MediaRecorder.isTypeSupported(IOS_SUPPORTED_MIMETYPE);

export const getPlatformInfo = () => {
  const userAgent = navigator.userAgent;
  const platform = navigator.platform;

  return {
    userAgent: userAgent,
    platform: platform,
    isMobile: /Mobi|Android/i.test(userAgent),
    isWindows: /Win/i.test(platform),
    isMac: /Mac/i.test(platform),
    isLinux: /Linux/i.test(platform),
    isIOS: /iPhone|iPad|iPod/i.test(userAgent),
    isAndroid: /Android/i.test(userAgent),
    isChrome: /Chrome/i.test(userAgent),
    isFirefox: /Firefox/i.test(userAgent),
    isSafari: /Safari/i.test(userAgent) && !/Chrome/i.test(userAgent),
  };
};

export function extractPoster(videoElement, file) {
  return new Promise(function (resolve, reject) {
    let createdVideoElement;
    if (!videoElement && !file) {
      reject("Couldn't find video element or file");
    }
    if (!videoElement && file) {
      createdVideoElement = document.createElement("video");
      createdVideoElement.id = "tempHiddenVideoElement";
      videoElement = createdVideoElement;
      // Load the video file
      videoElement.src = URL.createObjectURL(file);
    }
    // When the video is loaded and ready to play
    videoElement.addEventListener("loadeddata", function () {
      // Set the video to the first frame (0 seconds)
      videoElement.currentTime = 0;
    });

    // Once the first frame is loaded
    videoElement.addEventListener("seeked", function () {
      const canvasElement = document.createElement("canvas");
      // Set the canvas size to match the video dimensions
      canvasElement.width = videoElement.videoWidth;
      canvasElement.height = videoElement.videoHeight;

      // Draw the first frame of the video onto the canvas
      const context = canvasElement.getContext("2d");
      context.drawImage(
        videoElement,
        0,
        0,
        canvasElement.width,
        canvasElement.height
      );

      const posterDataUrl = canvasElement.toDataURL("image/png");
      const blob = dataURItoBlob(posterDataUrl);
      if (
        createdVideoElement ||
        document.getElementById("tempHiddenVideoElement")
      ) {
        document.getElementById("tempHiddenVideoElement")?.remove();
      }
      resolve(blob);
    });
  });
}

export function isValidHttpUrl(string) {
  let url;
  
  try {
    url = new URL(string);
  } catch (_) {
    return false;  
  }

  return url.protocol === "http:" || url.protocol === "https:";
}
