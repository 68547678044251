import React from "react";
import { Grid, Typography } from "@mui/material";
import UvenuLogo from "../../../assets/uvenu_logo.png";
import { COLORS } from "../../../utils/colors";

export default function TopBar({ userName, eventData }) {
  return (
    <div
      id="mobile-web-app-header"
      className="topBar mobile-web-app-header"
      alignItems="center"
      style={{
        height: "60px",
        backgroundColor:
          eventData && eventData.brandingBackgroundColor
            ? eventData.brandingBackgroundColor
            : COLORS.appBackground,
        zIndex: "1001",
        marginTop: 0,
        boxShadow:
          "0 4px 5px 0 rgba(0,0,0,.14), 0 1px 10px 0 rgba(0,0,0,.12), 0 2px 4px -1px rgba(0,0,0,.2)",
      }}
    >
      <img
        alt={"logo"}
        style={{ height: 50, margin: 5, objectFit: "contain" }}
        src={
          eventData && eventData.eventImageUrl
            ? eventData.eventImageUrl
            : UvenuLogo
        }
      />

      <div
        style={{ display: "flex", alignItems: "center" }}
        justifyContent="flex-end"
      >
        <div>
          <Typography
            color="primary"
            style={{ color: "white", marginRight: 8 }}
          >
            {userName ? userName : "Guest"}
          </Typography>
        </div>
      </div>
    </div>
  );
}
