import { useState, useCallback } from "react";
import Grid from "./Grid";

const InfiniteLoaderGrid = ({
  loadMoreItems,
  items,
  hasNextPage,
  activeTab,
  handleReviewMedia,
  pageSize,
  rowHeight,
  approved,
  columnCount
}) => {
  const [scrollState, setScrollState] = useState({
    rowIndex: 0,
    columnIndex: 0,
  });

  let isItemLoaded = (index) => !hasNextPage || !!items[index];
  const setScrollRowAndColum = useCallback((rowIndex, columnIndex) => {
    setScrollState({ rowIndex, columnIndex });
  }, []);

  return (
    <Grid
      hasNextPage={hasNextPage}
      items={items}
      loadMoreItems={loadMoreItems}
      isItemLoaded={isItemLoaded}
      scrollState={scrollState}
      setScrollRowAndColumn={setScrollRowAndColum}
      activeTab={activeTab}
      handleReviewMedia={handleReviewMedia}
      pageSize={pageSize}
      rowHeight={rowHeight}
      approved={approved}
      columnCount={columnCount}
    />
  );
};
export default InfiniteLoaderGrid;
