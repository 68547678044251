import "./ReviewMedia.css";
import { useState } from "react";
import { styled } from "@mui/system";
import { Tabs as BaseTabs } from "@mui/base/Tabs";
import { TabsList as BaseTabsList } from "@mui/base/TabsList";
import { TabPanel as BaseTabPanel } from "@mui/base/TabPanel";
import { buttonClasses } from "@mui/base/Button";
import { Tab as BaseTab, tabClasses } from "@mui/base/Tab";
import UnApproved from "./UnApproved";
import Approved from "./Approved";
const ReviewMedia = () => {
  const [activeTab, setActiveTab] = useState(0);
  const eventId = window.location.search.split("=")[1];

  return (
    <div className="reiewRootContainer">
      <UnApproved eventId={eventId} key={0}/>
      <Approved eventId={eventId} key={1}/>
      {/* <Tabs
        defaultValue={0}
        orientation="vertical"
        value={activeTab}
        onChange={(e, newValue) => {
          setActiveTab(newValue);
        }}
      >
        <TabsList>
          <Tab>Unapproved</Tab>
          <Tab>Approved</Tab>
        </TabsList>
        <TabPanel value={0}>
          
        </TabPanel>
        <TabPanel value={1}>
          
        </TabPanel>
      </Tabs> */}
    </div>
  );
};
export default ReviewMedia;
const blue = {
  50: "#F0F7FF",
  100: "#C2E0FF",
  200: "#80BFFF",
  300: "#66B2FF",
  400: "#3399FF",
  500: "#007FFF",
  600: "#0072E5",
  700: "#0059B2",
  800: "#004C99",
  900: "#003A75",
};

const grey = {
  50: "#F3F6F9",
  100: "#E5EAF2",
  200: "#DAE2ED",
  300: "#C7D0DD",
  400: "#B0B8C4",
  500: "#9DA8B7",
  600: "#6B7A90",
  700: "#434D5B",
  800: "#303740",
  900: "#1C2025",
};

const Tab = styled(BaseTab)`
  color: white;
  cursor: pointer;
  font-size: 0.875rem;
  font-weight: bold;
  background-color: transparent;
  width: 100%;
  padding: 12px;
  border: none;
  display: flex;
  justify-content: center;

  &:hover {
    background-color: #24b8e7;
  }

  &:focus {
    color: #fff;
  }

  &.${buttonClasses.focusVisible} {
    background-color: rgba(13, 14, 19, 1);
    color: #24b8e7;
  }

  &.${tabClasses.disabled} {
    opacity: 0.5;
    cursor: not-allowed;
  }

  &.${tabClasses.selected} {
    background-color: rgba(13, 14, 19, 1);
    color: #24b8e7;
  }
`;

const TabPanel = styled(BaseTabPanel)`
  width: 200%;
  height: 100%;
  font-size: 0.875rem;
  padding: 24px;
`;

const Tabs = styled(BaseTabs)`
  display: flex;
  width: 100%;
`;

const TabsList = styled(BaseTabsList)(
  ({ theme }) => `
    min-width: 100px;
    background-color:rgba(20, 24, 31, 1);;
    display: flex;
    padding: 6px 0px;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    align-content: space-between;
    `
);
