import React, { useRef, useEffect, memo } from "react";
import muted from "../../../../src/assets/muted.png";
import unmuted from "../../../../src/assets/unmuted.png";

const VideoPlayer = memo(
  ({
    type,
    source,
    mutedStatus,
    videoClicked,
    id,
    mediaHeight,
    adMediaHeight,
  }) => {
    const videoRef = useRef(null);
    let soundMuted = mutedStatus;
    useEffect(() => {
      const videoElement = videoRef.current;
      const handleIntersection = (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) videoElement.play();
          else videoElement.pause();
        });
      };

      const options = {
        threshold: 0.33, // 33% of the target element must be visible
      };
      const observer = new IntersectionObserver(handleIntersection, options);
      observer.observe(videoElement);
      return () => {
        observer.unobserve(videoElement);
      };
    }, []);

    const updateUIByMuteStatus = () => {
      soundMuted = !soundMuted;

      document.querySelectorAll(".jelsfmediaItemVideo").forEach((video) => {
        video.muted = true;
      });

      const videoId = type === "feed" ? `video-${id}` : `ad-video-${id}`;
      const video = document.getElementById(videoId);
      video.muted = soundMuted;

      document.querySelectorAll(".jelsfMuteIcon").forEach((img) => {
        img.src = unmuted;
      });
      const imgId = type === "feed" ? `mute-${id}` : `ad-mute-${id}`;
      const img = document.getElementById(imgId);
      img.src = soundMuted ? unmuted : muted;
    };

    return (
      <div style={{ position: "relative" }}>
        <video
          id={type === "feed" ? `video-${id}` : `ad-video-${id}`}
          className="jelsfmediaItemVideo skeleton"
          ref={videoRef}
          controls={false}
          loop
          muted={mutedStatus}
          style={{
            borderTopLeftRadius: type === "feed" ? 14 : 0,
            borderTopRightRadius: type === "feed" ? 14 : 0,
            height: type === "feed" ? `${mediaHeight}px` : `${adMediaHeight}px`,
          }}
          src={source}
          playsInline
        />

        <img
          id={type === "feed" ? `mute-${id}` : `ad-mute-${id}`}
          alt="mute"
          className="jelsfMuteIcon"
          src={unmuted}
          onClick={(e) => {
            updateUIByMuteStatus();
            videoClicked(e);
          }}
        />
      </div>
    );
  }
);

export default VideoPlayer;
