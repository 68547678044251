import { useState, useEffect } from "react";
const RandomColorStrobe = ({ color, animationDuration }) => {
  const [currentColor, setCurrentColor] = useState(color);
  useEffect(() => {
    setCurrentColor(color);
  }, [color]);
  return (
    <div
      style={{
        backgroundColor: currentColor,
        display: "flex",
        position: "absolute",
        inset: 0,
      }}
    ></div>
  );
};
export default RandomColorStrobe;
