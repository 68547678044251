import "./LightShowConsole.css";

import { useEffect } from "react";
import { socket } from "../../socket";
import { beatThreshold, detectionThreshold } from "./Common";
import { getRandomColor } from "../../utils/utility";

let audioContext;
let analyser;
let dataArray;
let randomColorSetMilliSeconds = null;

const LightShowConsole = ({ allShows, eventId, playEffect, settings }) => {
  const checkFrequency = (targetFrequency, _settings) => {
    analyser.getByteFrequencyData(dataArray);
    let intensity;
    let threshold;
    if (targetFrequency === 0) {
      // Live audio case
      threshold = beatThreshold - 1;
      intensity =
        dataArray.reduce((acc, val) => acc + val, 0) / dataArray.length;
    } else {
      // Tune frequncy case
      threshold = detectionThreshold;
      const binIndex = Math.round(
        targetFrequency / (audioContext.sampleRate / analyser.fftSize)
      );
      intensity = dataArray[binIndex];
    }
    const show = allShows.find((e) => e.targetFrequency === targetFrequency);
    if (show.effect === 4) {
      // Random color effect
      if (
        randomColorSetMilliSeconds === null ||
        Number(Date.now()) - Number(randomColorSetMilliSeconds) >
          show.animationDuration * 1000
      ) {
        randomColorSetMilliSeconds = Date.now();
        show.color = getRandomColor();
      }
    }
    // Send to web app users
    if (intensity) {
      socket.emit("data", {
        dataArray: intensity,
        settings: { ...show, ..._settings },
        eventId,
      });
    }

    // Play on preview
    if (intensity) {
      playEffect(intensity, threshold, show);
    }
  };
  const intervaIds = [];
  const startAudioDetection = async () => {
    try {
      audioContext = new (window.AudioContext || window.webkitAudioContext)();
      analyser = audioContext.createAnalyser();
      analyser.fftSize = 8192;
      dataArray = new Uint8Array(analyser.frequencyBinCount);

      const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
      const audioSource = audioContext.createMediaStreamSource(stream);
      const mediaStreamDestination =
        audioContext.createMediaStreamDestination();
      audioSource.connect(analyser);
      analyser.connect(mediaStreamDestination);
      mediaStreamDestination.stream.getAudioTracks().forEach((track) => {
        audioContext.suspend();
        audioContext.resume();
        track.stop();
      });
      allShows.forEach((show) => {
        const intervalMilliSeconds = show.targetFrequency === 0 ? 25 : 10;
        const checkInterval = setInterval(() => {
          checkFrequency(show.targetFrequency, settings);
        }, intervalMilliSeconds);
        intervaIds.push(checkInterval);
      });
    } catch (e) {
      alert("Web Audio API is not supported in this browser");
    }
  };
  const stopAudioDetection = () => {
    audioContext?.close();
  };
  useEffect(() => {
    startAudioDetection();
    return () => {
      stopAudioDetection();
    };
  }, []);

  const stop = () => {
    intervaIds.forEach(clearInterval);
  };
  useEffect(() => {
    if (allShows.length) {
      startAudioDetection();
    }
    return () => {
      stop();
    };
  }, [allShows]);
  return (
    <div style={{ width: "0px", height: "0px", visibility: "hidden" }}></div>
  );
};
export default LightShowConsole;
