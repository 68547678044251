import React, { useContext, useState } from "react";
import GoogleMapComponent from "../../components/GoogleMapComponent";
import Layout from "../../Layout";
import {
  Slider,
  Badge,
  Grid,
  Avatar,
  Button,
  Typography,
  TextField,
  CircularProgress,
  Tooltip,
  styled,
} from "@mui/material";
import "./styles.css";
import { URLS } from "../../constants";
import { uvenuFetcher } from "../../utils/uvenu-fetcher";
import { connect } from "redux-zero/react";

import { useNavigate } from "react-router-dom";
import Resizer from "react-image-file-resizer";

import BrandingOverlayManager from "../../components/BrandingOverlayManager";
import BrandingBackgroundManager from "../../components/BrandingBackgroundManager";
import AdsSetupManager from "../AdsSetupManager";
import UvMobileDatePicker from "../../components/UI/UvMobileDatePicker";
import { UvSliderStlyes } from "../../components/Styled/UvSliderStyles";
import { AD_TYPES, getEmailEntryConfig, getEmailEntryConfigForGames } from "../AdsSetupManager/functions";
import SocialSubmissionSettings from "../../components/SocialSubmissionSettings";
import { AppContext } from "../../contexts/App.Context";
import { COLORS } from "../../utils/colors";
import { AddAPhotoOutlined } from "@mui/icons-material";

const UvSlider = styled(Slider)(UvSliderStlyes);

function valuetext(value) {
  return `${value}meters`;
}

function CreateNewEvent(props) {
  const appContext = useContext(AppContext);
  const navigate = useNavigate();
  const { currentUser } = props;
  const [isLoading, setLoading] = useState(false);
  const [name, setName] = useState("");
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [coordinates, setCoordinates] = useState([]);
  const [radius, setRadius] = useState(300);
  const [imgSrcForPreview, setImgSrcForPreview] = useState("");
  const [imgDataURI, setImgDataURI] = useState("");

  const [adsSetupModalVisible, setAdsSetupModalVisibility] = useState(false);
  const [advertIds, setAdvertIds] = useState([]);

  const [
    brandingBackgroundModalVisibility,
    setBrandingBackgroundModalVisibility,
  ] = useState(false);
  const [brandingBackgroundModelObj, setBrandingBackgroundModelObj] = useState({
    brandingBackgroundBlurEnabled: false,
    brandingBackgroundColor: COLORS.appBackground,
    brandingBackgroundColorEnabled: false,
    brandingBackgroundImage: null,
    brandingBackgroundImageEnabled: false,
  });

  const [brandingModalVisibility, setBrandingModalVisibility] = useState(false);
  const [brandingModelObj, setBrandingModelObj] = useState({
    brandingOverlayImage: null,
    brandingOverlayImageEnabled: false,
  });

  const [socialSettingsVisibility, setSocialSettingsVisibility] =
    useState(false);
  const [savedSettingsObj, setSavedSettingsObj] = useState({
    videoDuration: 15,
    stillsEnabled: true,
    recordingEnabled: true,
    galleryEnabled: true,
    enableSocialSharingLinks: false,
    hashTags: "",
    itemBackgroundColor: COLORS.socialFeedCardBackground,
    showLocationForBulkUploadedMedia:false,
  });

  const handleStartDate = (date) => {
    setStartDate(date);
  };

  const handleEndDate = (date) => {
    setEndDate(date);
  };

  const upload = () => document.getElementById("selectImage").click();

  const resizeFile = (file) =>
    new Promise((resolve) => {
      Resizer.imageFileResizer(
        file,
        640,
        480,
        file.type === "image/png" ? "PNG" : "JPEG",
        100,
        0,
        (uri) => {
          resolve(uri);
        },
        "base64"
      );
    });

  //EVENT IMAGE HANDLE CODE
  const handleImageUpload = async (e) => {
    if (e.target.files.length > 0) {
      const _imgUrl = URL.createObjectURL(e.target.files[0]);
      setImgSrcForPreview(_imgUrl);
      const image = await resizeFile(e.target.files[0]);
      setImgDataURI(image);
    }
  };

  const setLatLng = (coords) => {
    if (coords) {
      let lat = coords.lat;
      let lng = coords.lng;
      let coordinates = [lng, lat]; // lng, lat because that's how its in mongo.
      setCoordinates(coordinates);
    }
  };

  const createEvent = async (e) => {
    e.preventDefault();
    if (
      name === "" ||
      startDate === "" ||
      endDate === "" ||
      coordinates.length === 0
    ) {
      appContext.triggerToast(true, {
        type: "warning",
        message: "All fields are required",
      });
      return;
    }

    if (imgDataURI === "") {
      appContext.triggerToast(true, {
        type: "warning",
        message: "Image is required",
      });
      return;
    }

    if (startDate > endDate) {
      appContext.triggerToast(true, {
        type: "warning",
        message: "End date should be greater than start date",
      });
      return;
    }

    setLoading(true);
    let location = { type: "Point", coordinates: coordinates };

    let collectContactInfoAt = "Never";
    const postliveAd = advertIds.filter(
      (a) => a.adType === AD_TYPES.POST_EVENT
    );
    let emailEntryConfig = {
      headerText: "",
      subHeaderText: "",
      position: "bottom",
    };
    if (postliveAd.length > 0) {
      collectContactInfoAt = postliveAd[0].adSelection
        ? AD_TYPES.POST_EVENT
        : "Never";
      emailEntryConfig = getEmailEntryConfig(postliveAd[0]);
    }

    let emailEntryConfigForGames = {
      headerText: "",
      subHeaderText: ""
    };
    const postgameAds = advertIds.filter(
      (a) => a.adType === AD_TYPES.POST_GAME
    );
    if (postgameAds.length > 0) {
      if (postgameAds[0].adSelection) {
        if (collectContactInfoAt === "Never") {
          collectContactInfoAt = AD_TYPES.POST_GAME;
        } else {
          collectContactInfoAt += ", " + AD_TYPES.POST_GAME;
        }
        emailEntryConfigForGames = getEmailEntryConfigForGames(postgameAds[0]);
      }
    }

    const payload = {
      name,
      location,
      radius,
      adverts: advertIds
        .filter((v) => v._id !== null)
        .map((v) => v._id)
        .join(","),
      startDate: (startDate.getTime() / 1000) | 0,
      endDate: (endDate.getTime() / 1000) | 0,
      picture: imgDataURI,
      brandingOverlayImage: brandingModelObj.brandingOverlayImage,
      brandingOverlayImageEnabled: brandingModelObj.brandingOverlayImageEnabled,
      brandingBackgroundImage:
        brandingBackgroundModelObj.brandingBackgroundImage,
      brandingBackgroundImageEnabled:
        brandingBackgroundModelObj.brandingBackgroundImageEnabled,
      brandingBackgroundColor:
        brandingBackgroundModelObj.brandingBackgroundColor,
      brandingBackgroundColorEnabled:
        brandingBackgroundModelObj.brandingBackgroundColorEnabled,
      brandingBackgroundBlurEnabled:
        brandingBackgroundModelObj.brandingBackgroundBlurEnabled,
      collectContactInfoAt: collectContactInfoAt,
      emailEntryConfig: emailEntryConfig,
      emailEntryConfigForGames,
      socialSubmissionSettings: savedSettingsObj,
    };
    try {
      const response = await uvenuFetcher({
        method: "POST",
        url: URLS.CREATE_EVENT,
        body: payload,
        headers: {
          token: `${currentUser.token}`,
        },
      });
      if (response.statusCode === 200) {
        appContext.triggerToast(true, {
          type: "success",
          message: "Event Created",
        });
        setTimeout(() => {
          setLoading(false);
          navigate("/events");
        }, 1000);
      } else {
        appContext.triggerToast(true, {
          type: "error",
          message: response.json.message,
        });
      }
    } catch (err) {
      console.log(err);
      setLoading(false);
    }
  };

  return (
    <div>
      <Layout title="CREATE NEW EVENT">
        <Grid container>
          <AdsSetupManager
            event={null}
            modalVisible={adsSetupModalVisible}
            handleCloseModal={() => setAdsSetupModalVisibility(false)}
            initialAdvertIds={advertIds}
            setAdvertIds={(newAdverts) => setAdvertIds(newAdverts)}
          />

          <BrandingOverlayManager
            modalVisible={brandingModalVisibility}
            handleCloseModal={() => setBrandingModalVisibility(false)}
            brandingModal={brandingModelObj}
            setBrandingModal={(newBrandingModal) =>
              setBrandingModelObj(newBrandingModal)
            }
          />

          <BrandingBackgroundManager
            modalVisible={brandingBackgroundModalVisibility}
            handleCloseModal={() => setBrandingBackgroundModalVisibility(false)}
            brandingModal={brandingBackgroundModelObj}
            setBrandingModal={(newBrandingModal) =>
              setBrandingBackgroundModelObj(newBrandingModal)
            }
          />

          <SocialSubmissionSettings
            modalVisible={socialSettingsVisibility}
            handleCloseModal={() => setSocialSettingsVisibility(false)}
            savedSettings={savedSettingsObj}
            setSavedSettings={(newSettings) => setSavedSettingsObj(newSettings)}
          />

          <Grid container className="gridContainer">
            <Grid item xs={4} className="gridItem">
              <Grid
                container
                spacing={3}
                justifyContent="center"
                alignItems="center"
                style={{ minHeight: "85vh" }}
              >
                <Grid item xs={12}>
                  <Grid container justifyContent="center">
                    <Grid item xs={10}>
                      <Typography
                        className="ceeTextLabel"
                        style={{
                          color: COLORS.textLabel,
                          textAlign: "center",
                          marginBottom: 0,
                        }}
                      >
                        Event Image
                        <span style={{ color: COLORS.error }}>{" *"}</span>
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>

                <Grid item xs={12}>
                  <Grid container>
                    <Grid item xs={12}>
                      <div className="ceeRoot">
                        <Badge
                          overlap="circular"
                          anchorOrigin={{
                            vertical: "bottom",
                            horizontal: "right",
                          }}
                          badgeContent={
                            <div
                              style={{ cursor: "pointer" }}
                              onClick={upload}
                              onChange={(e) => handleImageUpload(e)}
                            >
                              <input
                                id="selectImage"
                                hidden
                                type="file"
                                accept="image/*"
                              />
                              <Tooltip title="Select Thumbnail">
                                <Avatar
                                  style={{
                                    border: "1px solid",
                                    borderColor: COLORS.textHeader,
                                  }}
                                >
                                  <AddAPhotoOutlined />
                                </Avatar>
                              </Tooltip>
                            </div>
                          }
                        >
                          <Avatar
                            src={imgSrcForPreview ? imgSrcForPreview : "#"}
                            className="large"
                          />
                        </Badge>
                      </div>
                    </Grid>
                  </Grid>
                </Grid>

                <Grid item xs={12}>
                  <Grid container justifyContent="center">
                    <Grid item xs={10}>
                      <Typography
                        className="ceeTextLabel"
                        style={{ color: COLORS.textLabel }}
                      >
                        Event Name
                        <span style={{ color: COLORS.error }}>{" *"}</span>
                      </Typography>
                      <TextField
                        fullWidth
                        size="medium"
                        variant="filled"
                        hiddenLabel
                        placeholder="event name here"
                        inputProps={{
                          style: {
                            fontSize: 14,
                            color: COLORS.textHeader,
                            "&:-webkit-autofill": {
                              "transition-delay": 9999999999,
                            },
                            backgroundColor: COLORS.inputBackground,
                            borderRadius: 4,
                          },
                          maxLength: 20,
                        }}
                        onChange={(e) => setName(e.target.value)}
                      />
                    </Grid>
                  </Grid>
                </Grid>

                <Grid item xs={12}>
                  <Grid container justifyContent="center" spacing={3}>
                    <Grid item xs={5}>
                      <Typography
                        className="ceeTextLabel"
                        style={{ color: COLORS.textLabel }}
                      >
                        Start Date & Time
                      </Typography>
                      <UvMobileDatePicker
                        minDate={Date.now()}
                        closeOnSelect
                        hiddenLabel
                        format="MM/dd/yyyy hh:mm a"
                        value={startDate}
                        className="uv-datepicker"
                        onChange={handleStartDate}
                      />
                    </Grid>

                    <Grid item xs={5}>
                      <Typography
                        className="ceeTextLabel"
                        style={{ color: COLORS.textLabel }}
                      >
                        End Date & Time
                      </Typography>
                      <UvMobileDatePicker
                        minDate={startDate}
                        closeOnSelect
                        hiddenLabel
                        format="MM/dd/yyyy hh:mm a"
                        value={endDate}
                        className="uv-datepicker"
                        onChange={handleEndDate}
                      />
                    </Grid>
                  </Grid>
                </Grid>

                <Grid item xs={12}>
                  <Grid container justifyContent="center" spacing={3}>
                    <Grid item xs={5}>
                      <Typography
                        className="ceeTextLabel"
                        style={{ color: COLORS.textLabel }}
                      >
                        Latitude
                      </Typography>
                      <TextField
                        fullWidth
                        size="small"
                        variant="filled"
                        hiddenLabel
                        inputProps={{
                          style: {
                            fontSize: 14,
                            color: COLORS.textHeader,
                            "&:-webkit-autofill": {
                              "transition-delay": 9999999999,
                            },
                            backgroundColor: COLORS.inputBackground,
                            borderRadius: 4,
                          },
                        }}
                        value={
                          coordinates.length
                            ? coordinates[1].toFixed(5)
                            : "0.00"
                        }
                        disabled
                        sx={{
                          "& .MuiInputBase-input.Mui-disabled": {
                            WebkitTextFillColor: COLORS.textHeader,
                            opacity: 0.7,
                          },
                        }}
                      />
                    </Grid>

                    <Grid item xs={5}>
                      <Typography
                        className="ceeTextLabel"
                        style={{ color: COLORS.textLabel }}
                      >
                        Longitude
                      </Typography>
                      <TextField
                        fullWidth
                        size="small"
                        variant="filled"
                        hiddenLabel
                        inputProps={{
                          style: {
                            fontSize: 14,
                            color: COLORS.textHeader,
                            "&:-webkit-autofill": {
                              "transition-delay": 9999999999,
                            },
                            backgroundColor: COLORS.inputBackground,
                            borderRadius: 4,
                          },
                        }}
                        value={
                          coordinates.length
                            ? coordinates[0].toFixed(5)
                            : "0.00"
                        }
                        disabled
                        sx={{
                          "& .MuiInputBase-input.Mui-disabled": {
                            WebkitTextFillColor: COLORS.textHeader,
                            opacity: 0.7,
                          },
                        }}
                      />
                    </Grid>
                  </Grid>
                </Grid>

                <Grid item xs={12}>
                  <Grid container justifyContent="center">
                    <Grid item xs={10}>
                      <Grid container spacing={4}>
                        <Grid item xs={10}>
                          <Typography
                            className="ceeTextLabel"
                            style={{ color: COLORS.textLabel, marginBottom: 0 }}
                          >
                            Radius
                          </Typography>
                        </Grid>

                        <Grid item xs={12}>
                          <Grid container spacing={2}>
                            <Grid item xs={10}>
                              <UvSlider
                                onChange={(event, value) => {
                                  setRadius(value);
                                }}
                                defaultValue={radius}
                                getAriaValueText={valuetext}
                                aria-labelledby="discrete-slider-small-steps"
                                step={50}
                                marks
                                min={100}
                                max={1000}
                                valueLabelDisplay="on"
                              />
                            </Grid>

                            <Grid item xs={2}>
                              <Typography
                                className="ceeTextLabel"
                                style={{
                                  color: COLORS.textLabel,
                                  marginTop: 5,
                                  marginBottom: 0,
                                }}
                              >
                                meters
                              </Typography>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>

                <Grid item xs={10}>
                  <Grid container justifyContent="center" alignItems="flex-end">
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={(e) => setAdsSetupModalVisibility(true)}
                      className="fullWidth"
                    >
                      <Typography
                        className="ceeButton"
                        style={{ color: COLORS.textHeader }}
                      >
                        Setup Ads
                      </Typography>
                    </Button>
                  </Grid>
                </Grid>

                <Grid item xs={10}>
                  <Grid container justifyContent="center" alignItems="flex-end">
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={(e) =>
                        setBrandingBackgroundModalVisibility(true)
                      }
                      className="fullWidth"
                    >
                      <Typography
                        className="ceeButton"
                        style={{ color: COLORS.textHeader }}
                      >
                        Output background settings
                      </Typography>
                    </Button>
                  </Grid>
                </Grid>

                <Grid item xs={10}>
                  <Grid container justifyContent="center" alignItems="flex-end">
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={(e) => setBrandingModalVisibility(true)}
                      className="fullWidth"
                    >
                      <Typography
                        className="ceeButton"
                        style={{ color: COLORS.textHeader }}
                      >
                        Set output overlay
                      </Typography>
                    </Button>
                  </Grid>
                </Grid>

                <Grid item xs={10}>
                  <Grid container justifyContent="center" alignItems="flex-end">
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={(e) => setSocialSettingsVisibility(true)}
                      className="fullWidth"
                    >
                      <Typography
                        className="ceeButton"
                        style={{ color: COLORS.textHeader }}
                      >
                        Social Submission Settings
                      </Typography>
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>

            <div className="gridItem">
              <div className="googleMapContainer">
                <GoogleMapComponent
                  sliderRadius={radius}
                  onChangeLatLng={setLatLng}
                />
              </div>
              <div className="ceeActionsDiv">
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={(e) => navigate(-1)}
                  className="ceeCancelButton"
                  style={{
                    marginRight: 10,
                    color: COLORS.primary,
                    borderColor: COLORS.primary,
                  }}
                >
                  {isLoading ? (
                    <CircularProgress size={20} color="primary" />
                  ) : (
                    <Typography>Cancel</Typography>
                  )}
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={(e) => createEvent(e)}
                  className="createButton"
                  style={{ marginLeft: 10, color: COLORS.textHeader }}
                >
                  {isLoading ? (
                    <CircularProgress size={20} color="primary" />
                  ) : (
                    <Typography>Create Event</Typography>
                  )}
                </Button>
              </div>
            </div>
          </Grid>
        </Grid>
      </Layout>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    currentUser: state.currentUser,
  };
};
export default connect(mapStateToProps, null)(CreateNewEvent);
