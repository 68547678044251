import React, { useState, useEffect } from "react";
import {
  Button,
  TextField,
  FormControl,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
  Switch,
  FormGroup,
  Select,
  MenuItem,
  InputLabel,
  Grid,
  IconButton,
} from "@mui/material";
import { createGame, updateGame } from "./networkCalls/networkCalls";
import { connect } from "redux-zero/react";
import actions from "../../store/actions";
import Loader from "../../components/Loader/Loader";
import { MuiColorInput } from "mui-color-input";
import UvDialog from "../../components/UI/UvDialog";
import Option from "./Option";
import { v4 as uuidv4 } from "uuid";
import { CloudUploadOutlined } from "@mui/icons-material";
const validDataUrl = require("valid-data-url");

const QuestionSetterModal = (props) => {
  const { openQuestionSetter, setOpenQuestionSetter, gameData, editMode } =
    props;
  const loggedInUserObj = props.initialState.loggedInUserObj;
  const eventId = props.initialState.event_id;
  const [loading, setLoading] = useState(false);
  const [gameId, setGameId] = useState("");
  const [gameTitle, setGameTitle] = useState("");
  const [gameType, setGameType] = useState(1);
  const [question, setQuestion] = useState("");
  const [answers, setAnswers] = useState([]);
  const [correctAnswer, setCorrectAnswer] = useState("");
  const [isTimed, setIsTimed] = useState(false);
  const [timedSeconds, setTimedSeconds] = useState(60); // Default timer value of 60 seconds
  const [isLive, setIsLive] = useState(false);
  const [formErrors, setFormErrors] = useState({});
  const [gameImage, setGameImage] = useState(null); // State to hold the uploaded image file
  const [gameImageBase64, setGameImageBase64] = useState(null);
  const [postGameWinningMessage, setPostGameWinningMessage] = useState("");
  const [postGameLosingMessage, setPostGameLosingMessage] = useState("");
  const [gameResultsBackgroundImageUrl, setGameResultsBackgroundImageUrl] =
    useState(null);
  const [gameImageB64, setGameImageB64] = useState(null);
  const [gameBgB64, setGameBgB64] = useState(null);
  const [optionTextColor, setOptionTextColor] = useState("#000");
  const [highlightBarColor, setHighlightBarColor] = useState("#fff");

  const [isShowTotalVotes, setIsShowTotalVotes] = useState(
    gameData?.isShowTotalVotes || false
  );

  useEffect(() => {
    if (gameData) {
      setGameId(gameData.gameId);
      setGameType(gameData.gameType); // Set game type from the game data
      setGameTitle(gameData.gameTitle);
      setQuestion(gameData.question);
      setAnswers(gameData.options);
      setCorrectAnswer(gameData.correctAnswer);
      setIsTimed(gameData.isTimed);
      setIsLive(gameData.isLive);
      setTimedSeconds(gameData.timedSeconds || 60);
      setPostGameWinningMessage(gameData.postGameWinningMessage);
      setPostGameLosingMessage(gameData.postGameLosingMessage);
      setOptionTextColor(gameData.optionTextColor);
      setHighlightBarColor(gameData.highlightBarColor);

      if (gameData.gameResultsBackgroundImageUrl) {
        setGameResultsBackgroundImageUrl(
          gameData.gameResultsBackgroundImageUrl
        );
      }
      if (gameData.gameImageUrl) {
        setGameImageBase64(gameData.gameImageUrl);
      } else {
        setGameImageBase64(null);
      }
    } else {
      setGameType(1); // Reset game type
      setGameTitle("");
      setTimedSeconds(60);
    }

    // Pre-populate gameType when gameData exists
    if (gameData && gameData.gameType) {
      setGameType(gameData.gameType);
    }
  }, [gameData]);

  const handleGameTitleChange = (event) => {
    setGameTitle(event.target.value);
  };

  const handleGameTypeChange = (event) => {
    setGameType(event.target.value);
  };

  const handleQuestionChange = (event) => {
    setQuestion(event.target.value);
  };

  const handleAnswerChange = (index, text, image) => {
    const updatedAnswers = [...answers];
    updatedAnswers[index].optionText = text;
    updatedAnswers[index].optionImage = image;
    setAnswers(updatedAnswers);
  };

  const handleCorrectAnswerChange = (event) => {
    setCorrectAnswer(event.target.value);
  };

  const handleTimedSwitchChange = () => {
    setIsTimed(!isTimed);
  };

  const handleTimedSecondsChange = (event) => {
    setTimedSeconds(event.target.value);
  };

  const handleImageUpload = (event) => {
    const file = event.target.files[0];
    setGameImage(file);

    // Process the uploaded image into base64 format
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      setGameImageBase64(reader.result);
      setGameImageB64(reader.result);
    };
  };

  const handleGameBgUpload = (event) => {
    const file = event.target.files[0];
    // setGameResultsBackgroundImageUri(file);

    // Process the uploaded image into base64 format
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      setGameResultsBackgroundImageUrl(reader.result);
      setGameBgB64(reader.result);
    };
  };

  const validateForm = () => {
    const errors = {};

    if (!gameType) {
      errors.gameType = "Game Type is required";
    }
    if (!gameTitle.trim()) {
      errors.gameTitle = "Game Title is required";
    }
    if (!question.trim()) {
      errors.question = "Question is required";
    }
    if (answers.length < 2) {
      errors.answers = "At least 2 answer options are required";
    }
    if (answers.some((answer) => !answer.optionText)) {
      errors.answers = "Option cannot be empty";
    }
    if (gameType && gameType === 1 && !correctAnswer.trim()) {
      errors.correctAnswer = "Correct Answer is required";
    }
    if (gameType && gameType === 1 && !postGameWinningMessage.trim()) {
      errors.postGameWinningMessage = "Winning message is required!";
    }
    if (gameType && gameType === 1 && !postGameLosingMessage.trim()) {
      errors.postGameLosingMessage = "Losing message is required!";
    }
    if (!gameImage && !gameImageBase64) {
      errors.gameImage = "Game Image is required";
    }
    if (!gameResultsBackgroundImageUrl) {
      errors.gameResultsBackgroundImageUrl =
        "Game Image output background is required";
    }

    setFormErrors(errors);

    return Object.keys(errors).length === 0;
  };

  const pollGameSuccessMessage = "Thank you for joining the poll.";
  const handleSubmit = async () => {
    if (validateForm()) {
      const payload = {
        gameType: gameType.toString(),
        gameTitle: gameTitle,
        questionText: question,
        answerOptions: answers,
        correctAnswerText:
          correctAnswer !== "" ? correctAnswer : answers[0].optionText,
        isLive: isLive,
        isActive: true,
        isTimed: isTimed,
        postGameWinningMessage:
          postGameWinningMessage !== ""
            ? postGameWinningMessage
            : pollGameSuccessMessage,
        postGameLosingMessage:
          postGameLosingMessage !== ""
            ? postGameLosingMessage
            : pollGameSuccessMessage,
        timedSeconds: isTimed ? timedSeconds : null,
        highlightBarColor,
        optionTextColor,
        isShowTotalVotes,
      };
      if (gameImageB64) payload.gameImageUri = gameImageB64;
      if (gameBgB64) payload.gameResultsBackgroundImageUri = gameBgB64;

      if (editMode) {
        try {
          payload.answerOptions = await getConvertedAnswers(
            payload.answerOptions
          );
          if (!payload.gameId) payload.gameId = gameId;
          setLoading(true);
          await updateGame(payload, loggedInUserObj);
          setLoading(false);
        } catch (error) {
          console.log(error);
        }
        props.setEditModetoggle();
      } else {
        try {
          payload.eventId = eventId;
          payload.answerOptions = payload.answerOptions.map((option) => {
            delete option.keyId;
            return option;
          });
          setLoading(true);
          await createGame(payload, loggedInUserObj, gameImage);
          setLoading(false);
        } catch (error) {
          // Handle the error from the API call
          console.log(error);
        }
      }
      setOpenQuestionSetter();
      props.fetchGameData();
    }
  };

  const getConvertedAnswers = (options) => {
    const newOptions = [];
    for (let i = 0; i < options.length; i++) {
      const option = options[i];
      const copy = { ...option };
      if (copy.optionImage) {
        if (!validDataUrl(copy.optionImage)) {
          delete copy.optionImage;
        }
      }
      delete copy.keyId;
      newOptions.push(copy);
    }
    return newOptions;
  };
  const handleAddOption = () => {
    setAnswers((previous) => [
      ...previous,
      { keyId: uuidv4(), optionText: "", optionImage: null },
    ]);
  };

  const handleRemoveOption = (_id) => {
    setAnswers((previous) =>
      previous.filter((option) => option._id !== _id && option.keyId !== _id)
    );
  };

  return (
    <UvDialog
      show={openQuestionSetter}
      onClose={setOpenQuestionSetter}
      onSubmit={handleSubmit}
      title="Set Game Details"
      submitButtonLable="Submit"
    >
      {
        <div>
          {loading ? <Loader /> : null}
          <div
            style={{
              display: "flex",
              width: "100%",
              marginBottom: "8px",
            }}
          >
            <FormControl
              style={{
                width: "30%",
                marginRight: "10px",
                alignSelf: "flex-end",
              }}
              margin="normal"
              variant="outlined"
              error={!!formErrors.gameType}
            >
              <InputLabel>Game Type</InputLabel>
              <Select
                variant="standard"
                value={gameType}
                onChange={handleGameTypeChange}
                label="Game Type"
              >
                <MenuItem value={1}>Game</MenuItem>
                <MenuItem value={2}>Poll</MenuItem>
              </Select>
              {formErrors.gameType && (
                <div style={{ color: "red" }}>{formErrors.gameType}</div>
              )}
            </FormControl>
            <TextField
              style={{ width: "70%" }}
              label="Game Title"
              variant="outlined"
              fullWidth
              value={gameTitle}
              onChange={handleGameTitleChange}
              margin="normal"
              error={!!formErrors.gameTitle}
              helperText={formErrors.gameTitle}
            />
          </div>

          <TextField
            label="Question"
            variant="outlined"
            fullWidth
            value={question}
            onChange={handleQuestionChange}
            margin="normal"
            error={!!formErrors.question}
            helperText={formErrors.question}
          />
          <FormControl
            variant="standard"
            component="fieldset"
            margin="normal"
            fullWidth
          >
            <FormLabel component="legend" style={{ marginBottom: "0px" }}>
              {gameType === 1
                ? "Select correct answer colors"
                : "Select options color"}
            </FormLabel>
          </FormControl>
          <Grid
            container
            justifyContent="space-between"
            alignItems="center"
            style={{ marginBottom: "8px" }}
          >
            <Grid xs={12} sm={5} md={5} item alignItems="center">
              <Grid item>
                <InputLabel>Progress Bar:</InputLabel>
              </Grid>
              <Grid item>
                <MuiColorInput
                  value={highlightBarColor}
                  onChange={(newColor) => {
                    if (newColor) {
                      setHighlightBarColor(newColor);
                    }
                  }}
                />
              </Grid>
            </Grid>
            <Grid
              xs={12}
              sm={5}
              md={5}
              item
              alignItems="center"
              style={{ marginLeft: "8px" }}
            >
              <Grid item>
                <InputLabel>Text:</InputLabel>
              </Grid>
              <Grid item>
                <MuiColorInput
                  value={optionTextColor}
                  onChange={(newColor) => {
                    if (newColor) {
                      setOptionTextColor(newColor);
                    }
                  }}
                />
              </Grid>
            </Grid>
          </Grid>

          <FormControl variant="standard" component="fieldset" margin="normal">
            <FormLabel component="legend" style={{ marginBottom: "8px" }}>
              {gameType === 1 ? "Answers" : "Options"}
            </FormLabel>
            {answers.map((answer, index) => {
              const props = {
                answer,
                index,
                formErrors,
                handleRemoveOption,
                handleAnswerChange,
              };
              return <Option {...props} key={answer._id || answer.keyId} />;
            })}
            {formErrors.answers && (
              <div style={{ color: "red" }}>{formErrors.answers}</div>
            )}
            <Button
              variant="outlined"
              color="primary"
              onClick={handleAddOption}
              style={{ marginTop: "8px" }}
            >
              Add Option
            </Button>
          </FormControl>
          {gameType == 1 && (
            <FormControl
              variant="standard"
              component="fieldset"
              margin="normal"
              style={{ marginLeft: "28px" }}
              error={!!formErrors.correctAnswer}
            >
              <FormLabel component="legend">Correct Answer</FormLabel>
              <RadioGroup
                value={correctAnswer}
                onChange={handleCorrectAnswerChange}
              >
                {answers.map((answer) => (
                  <FormControlLabel
                    key={answer._id}
                    value={answer.optionText}
                    control={<Radio />}
                    label={answer.optionText}
                  />
                ))}
              </RadioGroup>
              {formErrors.correctAnswer && (
                <div style={{ color: "red" }}>{formErrors.correctAnswer}</div>
              )}
            </FormControl>
          )}

          {gameType == 1 && (
            <>
              <TextField
                label="Post Game Winning Message"
                variant="outlined"
                fullWidth
                value={postGameWinningMessage}
                onChange={(event) =>
                  setPostGameWinningMessage(event.target.value)
                }
                margin="normal"
                error={!!formErrors.postGameWinningMessage}
                helperText={
                  !postGameWinningMessage?.trim() &&
                  "Winning message is required"
                }
              />
              <TextField
                label="Post Game Losing Message"
                variant="outlined"
                fullWidth
                value={postGameLosingMessage}
                onChange={(event) =>
                  setPostGameLosingMessage(event.target.value)
                }
                margin="normal"
                error={!!formErrors.postGameLosingMessage}
                helperText={
                  !postGameLosingMessage?.trim() && "Losing message is required"
                }
              />
            </>
          )}
          <FormGroup row>
            <FormControlLabel
              control={
                <Switch
                  checked={isShowTotalVotes}
                  onChange={() => {
                    setIsShowTotalVotes((pre) => !pre);
                  }}
                  color="primary"
                />
              }
              label="Show total votes?"
            />
          </FormGroup>
          <FormGroup row>
            <FormControlLabel
              control={
                <Switch
                  checked={isTimed}
                  onChange={handleTimedSwitchChange}
                  color="primary"
                />
              }
              label="Timed"
            />
            {isTimed && (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <FormLabel
                  component="label"
                  className="timer-label"
                  style={{
                    fontSize: "10px",
                    marginRight: "8px",
                  }}
                >
                  Timer (secs):
                </FormLabel>
                <TextField
                  variant="outlined"
                  type="number"
                  value={timedSeconds}
                  onChange={handleTimedSecondsChange}
                  style={{ width: "100px" }}
                  inputProps={{
                    style: {
                      fontSize: "12px",
                      padding: "6px",
                    },
                  }}
                />
              </div>
            )}
            <div style={{ marginLeft: "16px" }} />{" "}
            {/* Added distance between switches */}
            <FormControlLabel
              control={
                <Switch
                  checked={isLive}
                  onChange={() => setIsLive(!isLive)}
                  color="primary"
                />
              }
              label="Live"
            />
          </FormGroup>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              marginBottom: "8px",
            }}
          >
            <div>
              <input
                type="file"
                onChange={handleImageUpload}
                id="upload-button"
                style={{ display: "none" }}
                required
              />

              <label htmlFor="upload-button" style={{ cursor: "pointer" }}>
                <IconButton
                  component="span"
                  color="primary"
                  aria-label="upload"
                  size="large"
                >
                  <CloudUploadOutlined />
                </IconButton>
                Upload Game Image
              </label>
              {gameImageBase64 && (
                <div
                  style={{
                    width: "100%",
                    marginTop: "16px",
                  }}
                >
                  <h4>Image Preview:</h4>
                  <div
                    style={{
                      width: "200px",
                      height: "200px",
                      overflow: "hidden",
                      margin: "auto",
                    }}
                  >
                    <img
                      src={gameImageBase64}
                      alt="Game"
                      style={{
                        width: "100%",
                        height: "100%",
                        objectFit: "contain",
                      }}
                    />
                  </div>
                </div>
              )}
              {formErrors.gameImage && (
                <div style={{ color: "red" }}>{formErrors.gameImage}</div>
              )}
            </div>
            <div
              style={{
                textAlign: "right",
                marginLeft: "12px",
              }}
            >
              <input
                type="file"
                onChange={handleGameBgUpload}
                id="upload-button-gbg"
                style={{ display: "none" }}
                required
              />

              <label htmlFor="upload-button-gbg" style={{ cursor: "pointer" }}>
                <IconButton
                  component="span"
                  color="primary"
                  aria-label="upload"
                  size="large"
                >
                  <CloudUploadOutlined />
                </IconButton>
                Upload game Output BG Image
              </label>
              {gameResultsBackgroundImageUrl && (
                <div
                  style={{
                    width: "100%",
                    marginTop: "16px",
                  }}
                >
                  <h4>Image Preview:</h4>
                  <div
                    style={{
                      width: "200px",
                      height: "200px",
                      overflow: "hidden",
                      margin: "auto",
                    }}
                  >
                    <img
                      src={gameResultsBackgroundImageUrl}
                      alt="Game"
                      style={{
                        width: "100%",
                        height: "100%",
                        objectFit: "contain",
                      }}
                    />
                  </div>
                </div>
              )}
              {formErrors.gameResultsBackgroundImageUrl && (
                <div style={{ color: "red" }}>
                  {formErrors.gameResultsBackgroundImageUrl}
                </div>
              )}
            </div>
          </div>
        </div>
      }
    </UvDialog>
  );
};

export default connect(
  (state) => ({ initialState: state.initialState }),
  actions
)(QuestionSetterModal);
