import React, { useEffect, useRef, useState } from "react";
import "./styles.css";
import { COLORS } from "../../../utils/colors";
import {
  Typography,
  IconButton,
  CircularProgress,
  Fab,
  Zoom,
  Link,
} from "@mui/material";
import nocontent from "../../../../src/assets/nocontent.png";

import { URLS } from "../../../constants";
import { uvenuFetcher } from "../../../utils/uvenu-fetcher";
import { likeMedia } from "../../Social/SocialOperator/networkCallsSocial";
import generateUserid from "../../../utils/generateUserid";
import {
  AD_TYPES,
  getAdInterval,
  getHyperlink,
} from "../../AdsSetupManager/functions";
import VideoPlayer from "./VideoPlayer";
import {
  openNativeShareControl,
  parseAddress,
} from "../../../utils/mediaFunctions";
import {
  FavoriteBorder,
  GpsFixed,
  KeyboardArrowUp,
  ScreenRotation,
  Send,
} from "@mui/icons-material";
import { getItemBackgroundColor } from "../../../components/SocialSubmissionSettings/functions";
import { useLoadScript } from "@react-google-maps/api";
import { format } from "date-fns";
import PullToRefresh from "react-simple-pull-to-refresh";
import InfiniteScrollList from "./InfiniteScrollList";
import {
  checkMediaOrientation,
  getOrientation,
} from "../../Social/fixedMasonry/CheckOrientation";
import { formatHyperlink, getPlatformInfo } from "../../../utils/utility";
import { LIBRARIES } from "../constants";
import SkeletonCard from "./SkeletonCard";

export function SocialFeed(props) {
  const { eventData, userId, advertsData, isPortrait, onSocialFeedScroll } =
    props;

  const [loading, setLoading] = useState(false);
  const [showFab, setShowFab] = useState(false);
  const [feedItems, setFeedItems] = useState([]);
  const [socialAdsData, setSocialAdsData] = useState([
    ...advertsData.filter((a) => a.adType == AD_TYPES.SOCIAL_AD),
  ]);

  const feedViewRef = useRef(null);
  let userID = useRef(userId);

  const pageSize = 5;
  const [page, setPage] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [hasNoMore, setHasNoMore] = useState(false);
  const [samePageTrigger, setSamePageTrigger] = useState(false);

  const geocoder = useRef(null);

  useEffect(() => {
    setLoading(true);
    loadSocialData(page);
  }, [page, samePageTrigger]);

  useEffect(() => {
    if (userID.current === "" || userID.current === null) {
      const databaseUniqueUserID = localStorage.getItem("databaseUniqueUserID");
      if (databaseUniqueUserID) {
        userID.current = databaseUniqueUserID;
      } else {
        userID.current = generateUserid();
      }
    }
  }, []);

  async function loadSocialData(page) {
    const gamesResponse = await uvenuFetcher({
      url: URLS.GET_SOCIAL_SCROLL(
        eventData._id,
        page,
        pageSize,
        null,
        null,
        "desc"
      ),
      method: "GET",
    });
    if (
      gamesResponse.json.mediaFiles &&
      gamesResponse.json.mediaFiles.length === 0
    ) {
      setHasNoMore(true);
      setLoading(false);
      // Handle the case when mediaFiles is empty
      return;
    }
    if (gamesResponse.json && gamesResponse.json.mediaFiles.length < pageSize) {
      setHasNoMore(true);
    } else {
      setHasNoMore(false);
    }

    let listItems = [];
    if (
      gamesResponse &&
      gamesResponse.json &&
      Array.isArray(gamesResponse.json.mediaFiles)
    ) {
      if (feedItems === null) {
        listItems = gamesResponse.json.mediaFiles;
      } else {
        listItems = mergeUnique(feedItems, gamesResponse.json.mediaFiles);
      }
    }
    await updateFeedItems(listItems);

    // Calculate total pages
    const totalPagesCalculated = Math.ceil(gamesResponse.json.totalCount / 8);
    setTotalPages(totalPagesCalculated);
    if (page < 2) {
      setShowFab(false);
    }
    setLoading(false);
    // Cleanup function
    return () => {};
  }

  const updateFeedItems = async (newItems) => {
    const newArray = [];
    for (const item of newItems) {
      if (item?.isRecorded && item?.isIosTypeSupported === false) {
        const platformInfo = getPlatformInfo();
        if (platformInfo.isIOS || platformInfo.isMac) {
          item["isUnsupportedMedia"] = true;
        }
      }
      try {
        const index = newItems.indexOf(item);
        let type = item.mimeType;
        if (type.includes("text")) {
          type = "video/webm";
        }

        if (item.metadata && item.metadata.width && item.metadata.height) {
          if (!item.orientation) {
            item.metadata["orientation"] = getOrientation(
              item.metadata.width,
              item.metadata.height
            ).orientation;
          }
        } else {
          const result = await checkImageOrientationAsync(
            item.mediaFileUrl,
            type,
            item._id
          );
          item.metadata = result;
        }
        const { orientation, width, height } = item.metadata;
        item.orientation = orientation;
        item.width = width;
        const { cardHeight, mediaHeight, adMediaHeight } =
          await getCalculatedItemHeight(item, width, height, index);
        item.height = cardHeight;
        item.mediaHeight = mediaHeight;
        item.adMediaHeight = adMediaHeight;
        item["address"] = "0, 0";
        if (item && item.userLocation && item.userLocation.coordinates) {
          if (!geocoder.current && window.google) {
            geocoder.current = new window.google.maps.Geocoder();
          }
          geocoder.current?.geocode(
            {
              location: {
                lat: item.userLocation.coordinates[1],
                lng: item.userLocation.coordinates[0],
              },
            },
            (results, status) => {
              if (status === "OK" && results[0]) {
                item["address"] = parseAddress(results[0].address_components);
              } else {
                item["address"] =
                  item.userLocation.coordinates[1] +
                  ", " +
                  item.userLocation.coordinates[0];
              }
            }
          );
        }
        newArray.push(item);
      } catch (error) {
        console.error("Error determining image orientation:", error.message);
      }
    }
    setFeedItems(newArray);
  };

  const getCalculatedItemHeight = async (item, width, height, index) => {
    const ratio = (window.innerWidth - 10) / width;
    const finalMediaHeight = height * ratio;
    let itemHeight =
      finalMediaHeight +
      36 /* buttons */ +
      (item.userId ? 19.98 : 0) /* username */ +
      19.5 /* location-time */ +
      20; /* extra padding */
    if (item.showOnQuotesFeed && item.showOnQuotesFeed === true) {
      const quoteHeight =
        item.comment.length === 0 ? 0 : item.comment.length < 50 ? 22 : 44;

      itemHeight = itemHeight + quoteHeight; /* quotes */
    }
    let adMediaHeight;
    if (showAdBasedOnIndex(index)) {
      const result = await checkImageOrientationAsync(
        getSocialAd(index).adMediaUrl,
        getSocialAd(index).mimeType
      );
      const { orientation, width, height } = result;
      const ratio = window.innerWidth / width;
      const finalAdMediaHeight = height * ratio;
      adMediaHeight = finalAdMediaHeight;
      itemHeight = itemHeight + finalAdMediaHeight + 50 /* action bar */;
    }

    return {
      cardHeight: itemHeight,
      mediaHeight: finalMediaHeight,
      adMediaHeight: adMediaHeight,
    };
  };

  const checkImageOrientationAsync = (imageUrl, mimeType, sharedMediaId) => {
    return new Promise((resolve, reject) => {
      checkMediaOrientation(
        imageUrl,
        mimeType,
        (error, result, errorEvent) => {
          if (error) {
            // console.error(error, errorEvent);
            // reject(new Error(error));
          } else {
            resolve(result);
          }
        },
        sharedMediaId
      );
    });
  };

  function mergeUnique(oldMediaFiles, newMediaFiles) {
    const uniqueNewMediaFiles = newMediaFiles.filter(
      (newMedia) =>
        !oldMediaFiles.some((oldMedia) => oldMedia._id === newMedia._id)
    );
    return [...oldMediaFiles, ...uniqueNewMediaFiles];
  }

  const incrementLikeCount = async (id) => {
    // Optimistically update the like count
    setFeedItems((prevItems) =>
      prevItems.map((item) =>
        item._id === id ? { ...item, likeCount: item.likeCount + 1 } : item
      )
    );

    // Then make the server request
    const response = await likeMedia(id, userID.current);

    // If the request failed, revert the like count
    if (response.status !== 201) {
      setFeedItems((prevItems) =>
        prevItems.map((item) =>
          item._id === id ? { ...item, likeCount: item.likeCount - 1 } : item
        )
      );
    }
  };

  const videoClicked = (item) => {
    resetAudioStatuses(true);
    let feedItemsCopy = [...feedItems];
    feedItemsCopy.forEach((feedItem) => {
      if (feedItem._id === item._id) {
        feedItem.muted = item.muted === undefined ? false : !item.muted;
      } else {
        feedItem.muted = true;
      }
    });
    setFeedItems(feedItemsCopy);
  };

  const showAdBasedOnIndex = (itemIndex) => {
    if (socialAdsData.length === 0) {
      return false;
    }
    let adIndex = itemIndex;
    let totalFreq = 0;
    for (const social of socialAdsData) {
      const freq = getAdInterval(social);
      totalFreq += freq;
    }
    if (itemIndex > totalFreq) {
      adIndex = itemIndex % totalFreq;
    }

    let prevFreq = 0;
    for (const socialAd of socialAdsData) {
      const sFreq = getAdInterval(socialAd) - 1;

      if (adIndex < sFreq) {
        return false;
      } else if (adIndex === sFreq + prevFreq) {
        return true;
      } else {
        prevFreq += sFreq + 1;
      }
    }
  };

  const getSocialAd = (itemIndex) => {
    let adIndex = itemIndex;
    let totalFreq = 0;
    for (const social of socialAdsData) {
      const freq = getAdInterval(social);
      totalFreq += freq;
    }
    if (itemIndex > totalFreq) {
      adIndex = itemIndex % totalFreq;
    }

    let prevFreq = 0;
    for (const socialAd of socialAdsData) {
      const sFreq = getAdInterval(socialAd) - 1;

      if (adIndex === sFreq + prevFreq) {
        return socialAd;
      } else {
        prevFreq += sFreq + 1;
      }
    }
  };

  const resetAudioStatuses = (fromItems) => {
    let feedItemsCopy = [...feedItems];
    feedItemsCopy.forEach((feedItem) => {
      if (!fromItems) {
        feedItem.muted = true;
      }
    });
    setFeedItems(feedItemsCopy);

    let feedItemsCopy2 = [...socialAdsData];
    feedItemsCopy2.forEach((feedItem) => {
      if (fromItems) {
        feedItem.soundEnabledIndex = 0;
        feedItem.muted = true;
      }
    });
    setSocialAdsData(feedItemsCopy2);
  };

  const adMuteToggleClicked = (itemIndex) => {
    const item = getSocialAd(itemIndex);
    resetAudioStatuses(false);
    let feedItemsCopy = [...socialAdsData];
    feedItemsCopy.forEach((feedItem) => {
      if (feedItem._id === item._id) {
        feedItem.soundEnabledIndex = itemIndex;
        feedItem.muted = item.muted === undefined ? false : !item.muted;
      } else {
        feedItem.soundEnabledIndex = 0;
        feedItem.muted = true;
      }
    });
    setSocialAdsData(feedItemsCopy);
  };

  const handleRefresh = async () => {
    setPage(0);
    setFeedItems([]);
    setShowFab(false);
    setLoading(true);
    await loadSocialData(0);
  };

  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: "AIzaSyCMSDz67I_529-1zB4GD8MdHBBMpXObnCA",
    libraries: LIBRARIES,
  });

  function RenderSocialFeedItem({ item }) {
    const itemIndex = feedItems.indexOf(item);
    const isImage = item.mimeType.split("/")[0] === "image";
    const isVideo =
      item.mimeType.split("/")[0] === "video" ||
      item.mimeType.split("/")[0] === "text";
    const getUserLocation = () =>
      item?.address?.length > 4 && (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          <GpsFixed
            style={{
              color: COLORS.textLabel,
              marginRight: 4,
              fontSize: "12px",
            }}
          />
          <Typography
            fontSize={"12px"}
            style={{
              color: COLORS.textHeader,
              textAlign: "start",
            }}
          >
            {item.address}
          </Typography>
        </div>
      );
    return (
      <>
        <div
          className="mediaItemContainer"
          style={{
            backgroundColor: getItemBackgroundColor(
              eventData.socialSubmissionSettings
            ),
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            {isImage && (
              <img
                src={item.mediaFileUrl}
                alt="Captured_Photo"
                className="jelsfMediaItem skeleton"
                height={`${item.mediaHeight}px`}
              />
            )}
            {isVideo && (
              <VideoPlayer
                type={"feed"}
                source={item.mediaFileUrl}
                mutedStatus={item.muted === undefined ? true : item.muted}
                videoClicked={() => {
                  // videoClicked(item);
                }}
                id={item?._id}
                mediaHeight={item?.mediaHeight}
                adMediaHeight={item?.adMediaHeight}
              />
            )}
          </div>
          <div className="textContent">
            <div style={{ margin: "-5px 0px -5px -5px" }}>
              {/* <IconButton
                onClick={() => {
                  openNativeShareControl(item.mediaFileUrl);
                }}
                aria-label="share"
              >
                <Send
                  fontSize="small"
                  sx={{
                    color: COLORS.textHeader,
                  }}
                />
              </IconButton> */}
              <IconButton
                aria-label="add to favorites"
                onClick={() => incrementLikeCount(item._id)}
                style={{ marginLeft: -8 }}
              >
                <FavoriteBorder
                  fontSize="small"
                  sx={{
                    color: COLORS.textHeader,
                  }}
                />
                <span
                  style={{ color: "white", marginLeft: 2, fontSize: "16px" }}
                >
                  {item.likeCount || 0}
                </span>
              </IconButton>
            </div>
            <Typography className="cardUsername">{item.userId}</Typography>

            {item.showOnQuotesFeed && item.showOnQuotesFeed === true && (
              <Typography className="cardDescription">
                {item.comment}
              </Typography>
            )}
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                opacity: 0.7,
              }}
            >
              {item.isBulkUploaded
                ? eventData?.socialSubmissionSettings
                    ?.showLocationForBulkUploadedMedia
                  ? getUserLocation()
                  : null
                : getUserLocation()}

              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                <Typography
                  fontSize={"13px"}
                  style={{
                    color: COLORS.textHeader,
                    textAlign: "end",
                  }}
                >
                  {format(
                    new Date(item.createdAt * 1000),
                    "dd MMM yyyy hh:mm a"
                  )}
                </Typography>
              </div>
            </div>
          </div>
        </div>
        {showAdBasedOnIndex(itemIndex) && (
          <Link
            className="adItem"
            href={formatHyperlink(getHyperlink(getSocialAd(itemIndex)))}
            target="_blank"
            underline="hover"
            sx={{
              backgroundColor: "transparent",
              textDecoration: "none",
              "&:focus": {
                outline: "none",
              },
              "&:hover": {
                backgroundColor: "transparent",
              },
              "&:active": {
                backgroundColor: "transparent",
              },
            }}
          >
            {getSocialAd(itemIndex).mimeType.includes("video") ? (
              <VideoPlayer
                type={"ad"}
                source={getSocialAd(itemIndex).adMediaUrl}
                mutedStatus={
                  getSocialAd(itemIndex).muted === undefined
                    ? true
                    : getSocialAd(itemIndex).soundEnabledIndex !== itemIndex
                    ? true
                    : getSocialAd(itemIndex).muted
                }
                videoClicked={(e) => {
                  // adMuteToggleClicked(itemIndex);
                  e.preventDefault();
                }}
                id={item?._id}
                mediaHeight={item?.mediaHeight}
                adMediaHeight={item?.adMediaHeight}
              />
            ) : (
              <img
                src={getSocialAd(itemIndex).adMediaUrl}
                alt="Captured_Photo"
                style={{ width: "100%" }}
              />
            )}
            {getSocialAd(itemIndex).adText.length > 0 ? (
              <div className="jelsfActionBar">
                <span className="adTitle">{getSocialAd(itemIndex).adText}</span>
                <span className="adAction">{">"}</span>
              </div>
            ) : (
              <div style={{ marginTop: -40, height: 20 }}></div>
            )}
          </Link>
        )}
      </>
    );
  }

  const [hasNextPage, setHasNextPage] = useState(true);

  const loadMoreItems = async (startIndex, stopIndex) => {
    const nextPageIndex = Math.floor(startIndex / pageSize);
    setShowFab(true);
    if (nextPageIndex > totalPages) {
      setSamePageTrigger(true);
      return;
    }
    setPage(nextPageIndex);
  };

  return (
    <div className="feedView" ref={feedViewRef}>
      {loading && (
        <div className="jelsfLoaderContainer">
          <CircularProgress style={{ color: COLORS.textHeader }} />
        </div>
      )}
      <div className="jelsfFeed">
        <PullToRefresh
          isPullable={feedItems.length > 0}
          pullingContent={
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                backgroundColor: COLORS.primary,
                width: "100%",
              }}
            >
              <CircularProgress style={{ color: COLORS.textHeader }} />
              <Typography
                style={{
                  color: COLORS.textHeader,
                }}
              >
                Release to refresh
              </Typography>
            </div>
          }
          onRefresh={handleRefresh}
        >
          {isPortrait && feedItems && feedItems.length > 0 ? (
            <InfiniteScrollList
              items={feedItems}
              pageSize={pageSize}
              hasNextPage={hasNextPage}
              loadMoreItems={loadMoreItems}
              onSocialFeedScroll={onSocialFeedScroll}
              RowItem={({ itemIndex, style, item }) => {
                return item?.isUnsupportedMedia ? (
                  <div className="invisibleItem" key={item._id}></div>
                ) : (
                  <div key={item._id} className="jelsfFeedItem" style={style}>
                    <RenderSocialFeedItem item={item} />
                  </div>
                );
              }}
            />
          ) : (
            <div className="noFeedItems">
              {isPortrait ? (
                <SkeletonCard />
              ) : (
                <ScreenRotation
                  style={{
                    marginTop: -50,
                    width: 50,
                    height: 50,
                    color: COLORS.textHeader,
                  }}
                />
              )}
              <Typography className="noFeedInfo">
                {isPortrait
                  ? feedItems.length === 0 && ""
                  : "Please rotate your device to view content."}
              </Typography>
            </div>
          )}
        </PullToRefresh>
      </div>
      <Zoom in={showFab}>
        <Fab
          sx={{
            position: "absolute",
            bottom: 16,
            right: 16,
            color: COLORS.textHeader,
            bgcolor: COLORS.gray,
            "&:hover": {
              bgcolor: COLORS.gray,
            },
            opacity: 0.7,
          }}
          onClick={handleRefresh}
        >
          <KeyboardArrowUp />
        </Fab>
      </Zoom>
    </div>
  );
}
