import {
  FormControlLabel,
  Checkbox,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
  Input,
  IconButton,
  Button,
  ToggleButton,
  ToggleButtonGroup,
  Grid,
  Switch,
} from "@mui/material";
import { MuiColorInput } from "mui-color-input";
import { useEffect, useReducer, useState } from "react";
import {
  getItemFromSessionStorage,
  saveItemToSessionStorage,
  getItemFromLocalStorage,
  saveItemToLocalStorage,
} from "../../utils/utility";

import {
  FIXED_MASONRY_LAYOUTS,
  SOCIAL_GRID_LAYOUTS,
  MAX_BACKGROUND_IMAGE_SIZE,
  WARNING_MESSAGE,
  FONTS,
} from "../../utils/defaultSettings";
import ApplySettingComponent from "./ApplySettingComponent";
import { VARIANT_LOCALSTORAGE_KEYS } from "../../utils/defaultSettings";
import QuoteCardSetting from "./QuoteCardSetting";
import { Wallpaper } from "@mui/icons-material";

function reducer(state, { type, value }) {
  switch (type) {
    case "change_layout": {
      return { ...state, selectedLayout: value };
    }
    case "change_grid_layout": {
      return { ...state, selectedGridLayoutOption: value };
    }
    case "change_bg_color": {
      return { ...state, backgroundColor: value };
    }
    case "change_border_color": {
      return { ...state, borderColor: value };
    }
    case "change_font_color": {
      return { ...state, fontColor: value };
    }
    case "change_flip_delay": {
      return { ...state, flipDelay: value };
    }
    case "change_gap": {
      return { ...state, gap: value };
    }
    case "change_border_width": {
      return { ...state, borderWidth: value };
    }
    case "change_rounded_corners": {
      return { ...state, roundedCorners: value };
    }
    case "change_background_image": {
      return { ...state, backgroundImage: value };
    }
    case "change_scroll_speed": {
      return { ...state, scrollSpeed: value };
    }
    case "change_random_transitions": {
      return { ...state, randomTransitions: value };
    }
    case "change_selected_transition": {
      return { ...state, selectedTransition: value };
    }
    case "change_show_arrows": {
      return { ...state, showArrows: value };
    }
    case "change_auto_play": {
      return { ...state, autoPlay: value };
    }
    case "change_carousel_speed": {
      return { ...state, carouselSpeed: value };
    }
    case "change_number_of_columns": {
      return { ...state, numberOfColumns: value };
    }
    case "change_number_of_rows": {
      return { ...state, numberOfRows: value };
    }
    case "change_card_orientation": {
      return { ...state, cardOrientation: value };
    }
    case "change_heading_font_size": {
      return { ...state, headingFontSize: value };
    }
    case "change_option_font_size": {
      return { ...state, optionFontSize: value };
    }
    case "change_show_likes": {
      return { ...state, showLikes: value };
    }
    case "change_show_name": {
      return { ...state, showName: value };
    }
    case "change_quote_card_setting": {
      return { ...state, quoteCards: value };
    }
    case "change_rotate_by": {
      return { ...state, mediaItemRotateBy: value };
    }
    case "change_x": {
      return { ...state, mediaItemX: value };
    }
    case "change_position": {
      return { ...state, position: value };
    }
    case "change_alternate_card": {
      return { ...state, setAlternateCard: value };
    }
    case "change_use_common_card": {
      return { ...state, useCommonCardStyle: value };
    }
    case "change_media_scale": {
      return { ...state, mediaCardScale: value };
    }
    case "change_show_quote_icon": {
      return { ...state, showQuoteIcon: value };
    }

    case "change_quote_font_size": {
      return { ...state, quoteFontSize: value };
    }
    case "change_slide_delay": {
      return { ...state, slideDelay: value };
    }
    case "change_alternate_position": {
      return { ...state, alternatePosition: value };
    }
    case "change_font": {
      return { ...state, font: value };
    }
    case "change_font_size": {
      return { ...state, fontSize: value };
    }
    case "change_state": {
      return value ? value : state;
    }
    default: {
      throw Error(`Unknown action ${type}`);
    }
  }
}

const SocialOutputSettings = ({
  onChange,
  localStorageUniqueKey,
  defaultSettings,
  useLocalStorage = false,
}) => {
  const [isImageSizeLarge, setIsImageSizeLarge] = useState(false);

  const [state, dispatch] = useReducer(reducer, defaultSettings);
  
  useEffect(() => {
    if (localStorageUniqueKey) {
      const newSettings = { ...state };
      for (let p in newSettings) {
        if (defaultSettings[p] === undefined) delete newSettings[p];
      }
      useLocalStorage
        ? saveItemToLocalStorage(localStorageUniqueKey, newSettings)
        : saveItemToSessionStorage(localStorageUniqueKey, newSettings);
    }
    onChange(state);
  }, [state]);

  const handleChange = (type, value) => {
    dispatch({ type, value });
  };

  const handleApplySettingChange = (
    listOfSelectedVariants,
    propertyToApply
  ) => {
    if (listOfSelectedVariants.length && propertyToApply) {
      for (let key in VARIANT_LOCALSTORAGE_KEYS) {
        const savedSettings = useLocalStorage
          ? getItemFromLocalStorage(VARIANT_LOCALSTORAGE_KEYS[key])
          : getItemFromSessionStorage(VARIANT_LOCALSTORAGE_KEYS[key]);
        const newSettings = {
          ...savedSettings,
          [propertyToApply]:
            listOfSelectedVariants.indexOf(VARIANT_LOCALSTORAGE_KEYS[key]) !==
            -1
              ? state[propertyToApply]
              : savedSettings[propertyToApply],
        };
        useLocalStorage
          ? saveItemToLocalStorage(VARIANT_LOCALSTORAGE_KEYS[key], newSettings)
          : saveItemToSessionStorage(
              VARIANT_LOCALSTORAGE_KEYS[key],
              newSettings
            );
      }
    }
  };

  return (
    <div data-lenis-prevent>
      {state.randomTransitions !== undefined ? (
        <>
          <FormControlLabel
            control={
              <Checkbox
                checked={state.randomTransitions}
                onChange={(e) => {
                  handleChange("change_random_transitions", e.target.checked);
                }}
                name="randomTransitionCheckbox"
                color="primary"
              />
            }
            label="Randomize Transitions"
          />
        </>
      ) : null}
      {state.randomTransitions !== undefined &&
      state.selectedTransition !== undefined &&
      state.randomTransitions === false ? (
        <FormControl variant="standard">
          <InputLabel id="transition-select-label">Transition</InputLabel>
          <Select
            variant="standard"
            labelId="transition-select-label"
            id="transition-select"
            value={state.selectedTransition}
            onChange={(e) => {
              handleChange("change_selected_transition", e.target.value);
            }}
          >
            <MenuItem value={"fade"}>Fade</MenuItem>
            <MenuItem value={"slide"}>Slide</MenuItem>
            <MenuItem value={"zoom"}>Zoom</MenuItem>
            <MenuItem value={"rotate"}>Rotate</MenuItem>
            <MenuItem value={"flip"}>Flip</MenuItem>
            <MenuItem value={"slideRight"}>Slide Right</MenuItem>
          </Select>
        </FormControl>
      ) : null}
      {state.selectedGridLayoutOption !== undefined ? (
        <FormControl fullWidth sx={{ marginTop: "20px" }}>
          <InputLabel id="select-label-grid">Select Layout</InputLabel>
          <Select
            labelId="select-label-grid"
            id="uv-select-grid"
            value={state.selectedGridLayoutOption}
            label="Select Layout"
            onChange={(e) => {
              handleChange("change_grid_layout", e.target.value);
            }}
          >
            {SOCIAL_GRID_LAYOUTS.map(({ value, displayValue }) => (
              <MenuItem value={value} key={value}>
                {displayValue}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      ) : null}
      {state.selectedLayout !== undefined ? (
        <FormControl fullWidth sx={{ marginTop: "20px" }}>
          <InputLabel id="select-label">Select Layout</InputLabel>
          <Select
            labelId="select-label"
            id="uv-select"
            value={state.selectedLayout}
            label="Select Layout"
            onChange={(e) => {
              handleChange("change_layout", e.target.value);
            }}
          >
            {FIXED_MASONRY_LAYOUTS.map(({ value, displayValue }) => (
              <MenuItem value={value} key={value}>
                {displayValue}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      ) : null}

      {state.scrollSpeed !== undefined ? (
        <FormControl variant="standard" fullWidth>
          <InputLabel>Scroll Speed</InputLabel>
          <Select
            variant="standard"
            label="Scroll Speed"
            value={state.scrollSpeed}
            fullWidth
            onChange={(e) => {
              handleChange("change_scroll_speed", e.target.value);
            }}
          >
            <MenuItem value={"crawl"}>Crawl</MenuItem>
            <MenuItem value={"slow"}>Slow</MenuItem>
            <MenuItem value={"medium"}>Medium</MenuItem>
            <MenuItem value={"fast"}>Fast</MenuItem>
          </Select>
        </FormControl>
      ) : null}

      {state.slideDelay !== undefined ? (
        <FormControl variant="standard" fullWidth sx={{ marginTop: "20px" }}>
          <InputLabel>Slide Delay (Seconds)</InputLabel>
          <Input
            id="slide-delay"
            type="number"
            inputProps={{ min: 1 }}
            value={state.slideDelay}
            onChange={(e) => {
              const newValue = e.target.value;
              handleChange("change_slide_delay", newValue);
            }}
          />
        </FormControl>
      ) : null}

      {state.numberOfColumns !== undefined ? (
        <FormControl variant="standard" fullWidth sx={{ marginTop: "20px" }}>
          <InputLabel>Number of Columns</InputLabel>
          <Select
            variant="standard"
            label="Number of columns"
            value={state.numberOfColumns}
            onChange={(e) => {
              handleChange("change_number_of_columns", e.target.value);
            }}
          >
            <MenuItem value={1}>1</MenuItem>
            <MenuItem value={2}>2</MenuItem>
            <MenuItem value={3}>3</MenuItem>
            <MenuItem value={4}>4</MenuItem>
            <MenuItem value={5}>5</MenuItem>
            <MenuItem value={6}>6</MenuItem>
            <MenuItem value={7}>7</MenuItem>
            <MenuItem value={8}>8</MenuItem>
          </Select>
        </FormControl>
      ) : null}
      {state.numberOfRows !== undefined ? (
        <FormControl variant="standard" fullWidth sx={{ marginTop: "20px" }}>
          <InputLabel>Number of Rows</InputLabel>
          <Select
            variant="standard"
            label="Number of rows"
            value={state.numberOfRows}
            onChange={(e) => {
              handleChange("change_number_of_rows", e.target.value);
            }}
          >
            <MenuItem value={1}>1</MenuItem>
            <MenuItem value={2}>2</MenuItem>
            <MenuItem value={3}>3</MenuItem>
            <MenuItem value={4}>4</MenuItem>
            <MenuItem value={5}>5</MenuItem>
          </Select>
        </FormControl>
      ) : null}
      {state.cardOrientation !== undefined ? (
        <FormControl variant="standard" fullWidth sx={{ marginTop: "20px" }}>
          <InputLabel>Card Orientation</InputLabel>
          <Select
            variant="standard"
            label="Card orientation"
            value={state.cardOrientation}
            onChange={(e) => {
              handleChange("change_card_orientation", e.target.value);
            }}
          >
            <MenuItem value={1}>Portrait</MenuItem>
            <MenuItem value={2}>Landscape</MenuItem>
          </Select>
        </FormControl>
      ) : null}
      {state.flipDelay !== undefined ? (
        <FormControl
          variant="standard"
          sx={{ width: "100%", marginTop: "20px" }}
        >
          <InputLabel htmlFor="timer-value">Flip Delay (seconds)</InputLabel>
          <Input
            id="timer-value"
            type="number"
            inputProps={{ min: 1 }}
            value={state.flipDelay}
            onChange={(e) => {
              const newValue = e.target.value;
              handleChange("change_flip_delay", newValue);
            }}
          />
        </FormControl>
      ) : null}
      {state.showArrows !== undefined ? (
        <FormControlLabel
          control={
            <Switch
              checked={state.showArrows}
              onChange={(e) => {
                handleChange("change_show_arrows", e.target.checked);
              }}
              color="primary"
            />
          }
          label="Show Slider Arrows"
        />
      ) : null}
      {state.autoPlay !== undefined ? (
        <Button
          onClick={() => {
            handleChange("change_auto_play", !state.autoPlay);
          }}
        >
          {state.autoPlay ? "Pause Carousel" : "Play Carousel"}
        </Button>
      ) : null}
      {state.carouselSpeed !== undefined ? (
        <FormControl variant="standard" fullWidth>
          <InputLabel>Carousel Speed</InputLabel>
          <Select
            variant="standard"
            value={state.carouselSpeed}
            onChange={(e) => {
              handleChange("change_carousel_speed", e.target.value);
            }}
          >
            <MenuItem value={3000}>Slow</MenuItem>
            <MenuItem value={1500}>Normal</MenuItem>
            <MenuItem value={1000}>Fast</MenuItem>
          </Select>
        </FormControl>
      ) : null}
      {state.headingFontSize !== undefined ? (
        <FormControl variant="standard" sx={{ width: "100%" }}>
          <InputLabel htmlFor="heading-font-size">
            Set Font Size for Heading
          </InputLabel>
          <Input
            id="heading-font-size"
            type="number"
            inputProps={{ min: 10 }}
            value={state.headingFontSize}
            onChange={(e) => {
              const newValue = e.target.value;
              handleChange("change_heading_font_size", newValue);
            }}
          />
        </FormControl>
      ) : null}
      {state.optionFontSize !== undefined ? (
        <FormControl
          variant="standard"
          sx={{ width: "100%", marginTop: "20px" }}
        >
          <InputLabel htmlFor="option-font-size">
            Set Font Size for Options
          </InputLabel>
          <Input
            id="option-font-size"
            type="number"
            inputProps={{ min: 10 }}
            value={state.optionFontSize}
            onChange={(e) => {
              const newValue = e.target.value;
              handleChange("change_option_font_size", newValue);
            }}
          />
        </FormControl>
      ) : null}
      {state.fontSize !== undefined ? (
        <FormControl
          variant="standard"
          sx={{ width: "100%", marginTop: "20px" }}
        >
          <InputLabel htmlFor="font-size">Font Size</InputLabel>
          <Input
            id="font-size"
            type="number"
            inputProps={{ min: 1 }}
            value={state.fontSize}
            onChange={(e) => {
              const newValue = e.target.value;
              handleChange("change_font_size", newValue);
            }}
          />
        </FormControl>
      ) : null}
      {state.font !== undefined ? (
        <FormControl
          variant="standard"
          fullWidth
          sx={{ width: "100%", margin: "20px 0px" }}
        >
          <InputLabel>Select Font</InputLabel>
          <Select
            variant="standard"
            value={state.font}
            onChange={(e) => {
              handleChange("change_font", e.target.value);
            }}
          >
            {FONTS.map((font) => (
              <MenuItem value={font.name} key={font.id}>
                {font.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      ) : null}
      {state.backgroundColor !== undefined ? (
        <>
          <Grid container spacing={2} columns={9} sx={{ mt: 2 }}>
            <Grid item xs={7}>
              <FormControl variant="standard" sx={{ width: "100%" }}>
                <MuiColorInput
                  format="rgb"
                  value={state.backgroundColor}
                  label="Select Background color"
                  onChange={(newColor) => {
                    handleChange("change_bg_color", newColor);
                  }}
                />
              </FormControl>
            </Grid>
            <Grid item xs={2}>
              <ApplySettingComponent
                defaultSelection={localStorageUniqueKey}
                localStorageKey="uvSettingBackgroundColor"
                resetWithChangeIn={state.backgroundColor}
                onChange={(newValue) =>
                  handleApplySettingChange(newValue, "backgroundColor")
                }
              />
            </Grid>
          </Grid>

          <FormControl variant="standard"></FormControl>
        </>
      ) : null}
      {state.fontColor !== undefined ? (
        <>
          <Grid container spacing={2} columns={9}>
            <Grid item xs={7}>
              <FormControl variant="standard" sx={{ width: "100%" }}>
                <MuiColorInput
                  value={state.fontColor}
                  label="Select font color"
                  onChange={(newColor) => {
                    handleChange("change_font_color", newColor);
                  }}
                />
              </FormControl>
            </Grid>
            <Grid item xs={2}>
              <ApplySettingComponent
                defaultSelection={localStorageUniqueKey}
                localStorageKey="uvSettingFontColor"
                onChange={(newValue) =>
                  handleApplySettingChange(newValue, "fontColor")
                }
              />
            </Grid>
          </Grid>
          <FormControl variant="standard"></FormControl>
        </>
      ) : null}
      {state.borderColor !== undefined ? (
        <>
          <Grid container spacing={2} columns={9}>
            <Grid item xs={7}>
              <FormControl variant="standard" sx={{ width: "100%" }}>
                <MuiColorInput
                  value={state.borderColor}
                  label="Select Border color"
                  onChange={(newColor) => {
                    handleChange("change_border_color", newColor);
                  }}
                />
              </FormControl>
            </Grid>
            <Grid item xs={2}>
              <ApplySettingComponent
                defaultSelection={localStorageUniqueKey}
                localStorageKey="uvSettingBorderColor"
                onChange={(newValue) =>
                  handleApplySettingChange(newValue, "borderColor")
                }
              />
            </Grid>
          </Grid>
          <FormControl variant="standard"></FormControl>
        </>
      ) : null}

      {state.borderWidth !== undefined ? (
        <>
          <Grid container spacing={2} columns={9}>
            <Grid item xs={7}>
              <FormControl variant="standard" sx={{ width: "100%" }}>
                <InputLabel htmlFor="border-width-value">
                  Border Width(0-10 pixels)
                </InputLabel>
                <Input
                  id="border-width-value"
                  type="number"
                  inputProps={{ min: 0, max: 10 }}
                  value={state.borderWidth}
                  onChange={(e) => {
                    const newValue = e.target.value;
                    handleChange("change_border_width", newValue);
                  }}
                />
              </FormControl>
            </Grid>
            <Grid item xs={2}>
              <ApplySettingComponent
                defaultSelection={localStorageUniqueKey}
                localStorageKey="uvSettingBorderWidth"
                onChange={(newValue) =>
                  handleApplySettingChange(newValue, "borderWidth")
                }
              />
            </Grid>
          </Grid>
          <FormControl variant="standard"></FormControl>
        </>
      ) : null}
      {state.gap !== undefined ? (
        <>
          <Grid container spacing={2} columns={9}>
            <Grid item xs={7}>
              <FormControl variant="standard" fullWidth>
                <InputLabel htmlFor="gap-value">Gap</InputLabel>
                <Input
                  id="gap-value"
                  type="number"
                  inputProps={{ min: 8 }}
                  value={state.gap}
                  onChange={(e) => {
                    const newValue = e.target.value;
                    handleChange("change_gap", newValue);
                  }}
                />
              </FormControl>
            </Grid>
            <Grid item xs={2}>
              <ApplySettingComponent
                defaultSelection={localStorageUniqueKey}
                localStorageKey="uvSettingGap"
                onChange={(newValue) =>
                  handleApplySettingChange(newValue, "gap")
                }
              />
            </Grid>
          </Grid>
          <FormControl variant="standard"></FormControl>
        </>
      ) : null}

      {state.roundedCorners !== undefined ? (
        <>
          <Grid container spacing={2} columns={9}>
            <Grid item xs={7}>
              <FormControl variant="standard" sx={{ width: "100%" }}>
                <FormControlLabel
                  control={
                    <Checkbox
                      value={state.roundedCorners}
                      checked={Boolean(state.roundedCorners)}
                      onChange={() => {
                        handleChange(
                          "change_rounded_corners",
                          !Boolean(state.roundedCorners)
                        );
                      }}
                    />
                  }
                  label="Rounded corners?"
                />
              </FormControl>
            </Grid>
            <Grid item xs={2}>
              <ApplySettingComponent
                defaultSelection={localStorageUniqueKey}
                localStorageKey="uvSettingRoundedCorners"
                onChange={(newValue) =>
                  handleApplySettingChange(newValue, "roundedCorners")
                }
              />
            </Grid>
          </Grid>
          <FormControl variant="standard"></FormControl>
        </>
      ) : null}
      {state.showLikes !== undefined || state.showName !== undefined ? (
        <>
          <Grid container spacing={2} columns={12}>
            {state.showLikes !== undefined ? (
              <Grid item xs={5}>
                <FormControl variant="standard" sx={{ width: "100%" }}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        value={state.showLikes}
                        checked={Boolean(state.showLikes)}
                        onChange={() => {
                          handleChange(
                            "change_show_likes",
                            !Boolean(state.showLikes)
                          );
                        }}
                      />
                    }
                    label="Show likes?"
                  />
                </FormControl>
              </Grid>
            ) : null}
            {state.showName !== undefined ? (
              <>
                <Grid item xs={5}>
                  <FormControl variant="standard" sx={{ width: "100%" }}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          value={state.showName}
                          checked={Boolean(state.showName)}
                          onChange={() => {
                            handleChange(
                              "change_show_name",
                              !Boolean(state.showName)
                            );
                          }}
                        />
                      }
                      label="Show name?"
                    />
                  </FormControl>
                </Grid>
              </>
            ) : null}
          </Grid>
          <FormControl variant="standard"></FormControl>
        </>
      ) : null}

      {state.backgroundImage !== undefined ? (
        <>
          <Grid container spacing={2} columns={9}>
            <Grid item xs={7}>
              <div>
                <label htmlFor="background-image">Background Image:</label>
                <input
                  type="file"
                  id="background-image"
                  onChange={(event) => {
                    const file = event.target.files[0];
                    if (file) {
                      if (file.size > MAX_BACKGROUND_IMAGE_SIZE) {
                        setIsImageSizeLarge(true);
                      } else {
                        setIsImageSizeLarge(false);
                      }
                      const reader = new FileReader();
                      reader.onloadend = () => {
                        const newImage = reader.result;
                        handleChange("change_background_image", newImage);
                      };
                      reader.readAsDataURL(file);
                    }
                  }}
                  accept="image/*"
                  style={{ display: "none" }}
                />
                <label htmlFor="background-image">
                  <IconButton
                    component="span"
                    color="primary"
                    aria-label="upload"
                    size="large"
                  >
                    <Wallpaper />
                  </IconButton>
                  Upload BG Image
                </label>
                {isImageSizeLarge && state.backgroundImage && (
                  <p style={{ color: "#ed6c02" }}>{WARNING_MESSAGE}</p>
                )}
              </div>
            </Grid>
            <Grid item xs={2}>
              <ApplySettingComponent
                defaultSelection={localStorageUniqueKey}
                localStorageKey="uvSettingBackgroundImage"
                onChange={(newValue) =>
                  handleApplySettingChange(newValue, "backgroundImage")
                }
              />
            </Grid>
          </Grid>
          {state.backgroundImage && (
            <>
              <img
                src={state.backgroundImage}
                alt="Background Preview"
                style={{
                  width: "100%",
                  maxHeight: "150px",
                  margin: "10px 0",
                  objectFit: "cover",
                }}
              />
              <Button
                fullWidth
                variant="outlined"
                onClick={() => {
                  handleChange("change_background_image", null);
                }}
              >
                Remove Background Image
              </Button>
            </>
          )}
        </>
      ) : null}

      {state.quoteFontSize !== undefined ? (
        <FormControl
          variant="standard"
          sx={{ width: "100%", marginTop: "20px" }}
        >
          <InputLabel htmlFor="quote-font-size">Set Quote Font Size</InputLabel>
          <Input
            id="quote-font-size"
            type="number"
            inputProps={{ min: 10 }}
            value={state.quoteFontSize}
            onChange={(e) => {
              const newValue = e.target.value;
              handleChange("change_quote_font_size", newValue);
            }}
          />
        </FormControl>
      ) : null}

      {state.mediaItemRotateBy !== undefined ? (
        <FormControl
          variant="standard"
          sx={{ width: "100%", marginTop: "20px" }}
        >
          <InputLabel htmlFor="tilt-value">
            Rotate Media Card by(Degrees)
          </InputLabel>
          <Input
            id="rotate-value"
            type="number"
            inputProps={{ min: -180, max: 180 }}
            value={state.mediaItemRotateBy}
            onChange={(e) => {
              const newValue = e.target.value;
              handleChange("change_rotate_by", newValue);
            }}
          />
        </FormControl>
      ) : null}
      {state.mediaItemX !== undefined ? (
        <FormControl
          variant="standard"
          sx={{ width: "100%", margin: "20px 0px" }}
        >
          <InputLabel htmlFor="tilt-value">Move Horizontally by(Px)</InputLabel>
          <Input
            id="x-value"
            type="number"
            value={state.mediaItemX}
            onChange={(e) => {
              const newValue = e.target.value;
              handleChange("change_x", newValue);
            }}
          />
        </FormControl>
      ) : null}
      {state.position !== undefined ? (
        <FormControl
          variant="standard"
          sx={{ width: "100%", margin: "20px 0px" }}
        >
          <ToggleButtonGroup
            color="primary"
            value={state.position}
            exclusive
            onChange={(e) => {
              const newValue = e.target.value;
              handleChange("change_position", newValue);
            }}
            aria-label="Platform"
          >
            <ToggleButton value="left">Left</ToggleButton>
            <ToggleButton value="right">Right</ToggleButton>
          </ToggleButtonGroup>
        </FormControl>
      ) : null}
      {state.alternatePosition !== undefined ? (
        <>
          <FormControlLabel
            control={
              <Checkbox
                checked={state.alternatePosition}
                onChange={(e) => {
                  handleChange("change_alternate_position", e.target.checked);
                }}
                name="alternatePosition"
                color="primary"
              />
            }
            label="Set alternate position of option image?"
          />
        </>
      ) : null}
      {state.mediaCardScale !== undefined ? (
        <FormControl variant="standard" sx={{ width: "100%" }}>
          <InputLabel htmlFor="media-scale-value">
            Media Scale (10% - 200%)
          </InputLabel>
          <Input
            id="media-scale-value"
            type="number"
            inputProps={{ min: 10, max: 200 }}
            value={state.mediaCardScale}
            onChange={(e) => {
              const newValue = e.target.value;
              if (newValue > 9 && newValue < 201) {
                handleChange("change_media_scale", newValue);
              }
            }}
          />
        </FormControl>
      ) : null}

      {state.showQuoteIcon !== undefined ? (
        <>
          <FormControlLabel
            sx={{ marginTop: "20px", width: "100%" }}
            control={
              <Checkbox
                checked={state.showQuoteIcon}
                onChange={(e) => {
                  handleChange("change_show_quote_icon", e.target.checked);
                }}
                name="quoteIconCheckbox"
                color="primary"
              />
            }
            label="Show quote icon"
          />
        </>
      ) : null}

      {state.useCommonCardStyle !== undefined ? (
        <>
          <FormControlLabel
            control={
              <Checkbox
                checked={state.useCommonCardStyle}
                onChange={(e) => {
                  if (e.target.checked) {
                    handleChange("change_alternate_card", false);
                  }

                  handleChange("change_use_common_card", e.target.checked);
                }}
                name="commonCardCheckbox"
                color="primary"
              />
            }
            label="Select common quote background?"
          />
        </>
      ) : null}

      {state.setAlternateCard !== undefined ? (
        <>
          <FormControlLabel
            control={
              <Checkbox
                checked={state.setAlternateCard}
                onChange={(e) => {
                  if (e.target.checked) {
                    handleChange("change_use_common_card", false);
                  }
                  handleChange("change_alternate_card", e.target.checked);
                }}
                name="alternateCardCheckbox"
                color="primary"
              />
            }
            label="Select alternate quote background?"
          />
        </>
      ) : null}
      {state.quoteCards && state.quoteCards.length !== undefined
        ? state.quoteCards.map((cardSetting, index) => {
            let maxCards = state.setAlternateCard === true ? 2 : 1;
            return state.setAlternateCard === true ||
              state.useCommonCardStyle ? (
              index < maxCards ? (
                <QuoteCardSetting
                  key={cardSetting.id}
                  defaultSetting={cardSetting}
                  index={index}
                  isCommon={state.useCommonCardStyle}
                  onChange={(newState, index) => {
                    const newQuoteCards = [...state.quoteCards];
                    newQuoteCards[index] = newState;

                    handleChange("change_quote_card_setting", newQuoteCards);
                  }}
                />
              ) : null
            ) : (
              <QuoteCardSetting
                key={cardSetting.id}
                defaultSetting={cardSetting}
                index={index}
                onChange={(newState, index) => {
                  const newQuoteCards = [...state.quoteCards];
                  newQuoteCards[index] = newState;
                  handleChange("change_quote_card_setting", newQuoteCards);
                }}
              />
            );
          })
        : null}
    </div>
  );
};
export default SocialOutputSettings;
