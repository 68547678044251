import AddLightShow from "./AddLightShow";
import "./LightShow.css";
import { useEffect, useState, useContext, useRef } from "react";
import {
  getLightShowsForEvent,
  deleteLightShow,
  saveLightShowSettings,
} from "../Games/networkCalls/networkCalls";
import { useLocation } from "react-router-dom";
import LightShowPreview from "./LightShowPreview";
import LightShowConsole from "./LightShowConsole";
import LightShowDetails from "./LightShowDetails";
import ConnectionManager from "../../components/Socket/ConnectionManager";
import { Delete, Edit } from "@mui/icons-material";
import { AppContext } from "../../contexts/App.Context";
import {
  Typography,
  IconButton,
  Tooltip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  DialogContentText,
  Button,
  Paper,
  Grid,
  FormControl,
  FormControlLabel,
  Checkbox,
  InputLabel,
  Input,
} from "@mui/material";
import { Settings, Wallpaper } from "@mui/icons-material";
import { COLORS } from "../../utils/colors";
import UvDialog from "../../components/UI/UvDialog";
import { getLightShowSettingsForEvent } from "../Games/networkCalls/networkCalls";
import Loader from "../../components/Loader/Loader";
const validDataUrl = require("valid-data-url");
const LightShowHome = () => {
  const [addShow, setAddShow] = useState(false);
  const [totalShows, setTotalShows] = useState(0);
  const [showList, setShowList] = useState([]);
  const [selectedShow, setSelectedShow] = useState("");
  const [isConnected, setIsConnected] = useState(false);
  const [expand, setExpand] = useState(false);
  const location = useLocation();
  const appContext = useContext(AppContext);
  const [showConfirmationDialog, setShowConfirmationDialog] = useState(false);
  const [showSettings, setShowSettings] = useState(false);
  const [selectedMediaDataUrl, setSelectedMediaDataUrl] = useState(null);
  const [animationSpeed, setAnimationSpeed] = useState(1);
  const [holdDuration, setHoldDuration] = useState(4);
  const [repeatDelay, setRepeatDelay] = useState(10);
  const [savedSettings, setSavedSettings] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [intensity, setIntensity] = useState(0);
  const [threshold, setThreshold] = useState(0);
  const [show, setShow] = useState({});
  const [isAnimateLogo, setIsAnimateLogo] = useState(true);
  const addLightShow = () => {
    setAddShow(true);
  };
  const getData = async () => {
    const list = await getLightShowsForEvent(location.state.event.event._id);
    setShowList(list || []);
    setSelectedShow(list[0]);
    setTotalShows(list.length || 0);
    setExpand(false);
  };

  const deleteShow = async () => {
    await deleteLightShow(selectedShow._id);
    setShowConfirmationDialog(false);

    appContext.triggerToast(true, {
      type: "success",
      message: "Light show deleted successfully.",
    });
    getData();
  };
  const onLightShowUpdate = () => {
    appContext.triggerToast(true, {
      type: "success",
      message: "Light show updated successfully.",
    });
    getData();
  };

  useEffect(() => {
    getData();
    geteventSettings(location.state.event.event._id);
  }, []);

  const renderDeleteConfirmationDialog = () => {
    return showConfirmationDialog ? (
      <Dialog
        open={showConfirmationDialog}
        onClose={() => {
          setShowConfirmationDialog(false);
        }}
      >
        <Paper style={{ backgroundColor: COLORS.popupBackground }}>
          <DialogTitle style={{ color: COLORS.textHeader }}>
            {"Delete Confirmation"}
          </DialogTitle>
          <DialogContent>
            <DialogContentText style={{ color: COLORS.textHeader }}>
              {`Are you sure you want to delete ${selectedShow.title}?`}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={deleteShow}>Delete</Button>
            <Button
              onClick={() => {
                setShowConfirmationDialog(false);
              }}
            >
              Cancel
            </Button>
          </DialogActions>
        </Paper>
      </Dialog>
    ) : null;
  };
  const saveSettings = async () => {
    const mediaForAudienceScreen = selectedMediaDataUrl
      ? validDataUrl(selectedMediaDataUrl)
        ? selectedMediaDataUrl
        : undefined
      : null;
    const payload = {
      animationSpeed,
      repeatDelay,
      holdDuration,
      mediaForAudienceScreen,
      isAnimateLogo,
      eventId: location.state.event.event._id,
    };
    setIsLoading(true);
    const response = await saveLightShowSettings(payload);
    appContext.triggerToast(true, {
      type: "success",
      message: response,
    });
    geteventSettings(location.state.event.event._id);
    setIsLoading(false);
    setShowSettings(false);
  };

  useEffect(() => {
    if (savedSettings) {
      if (savedSettings.mediaForAudienceScreen) {
        setSelectedMediaDataUrl(savedSettings.mediaForAudienceScreen);
      }
      if (
        savedSettings.animationSpeed !== null &&
        savedSettings.animationSpeed !== undefined
      ) {
        setAnimationSpeed(savedSettings.animationSpeed);
      }
      if (
        savedSettings.repeatDelay !== null &&
        savedSettings.repeatDelay !== undefined
      ) {
        setRepeatDelay(savedSettings.repeatDelay);
      }
      if (
        savedSettings.holdDuration !== null &&
        savedSettings.holdDuration !== undefined
      ) {
        setHoldDuration(savedSettings.holdDuration);
      }
    }
  }, [savedSettings]);

  const geteventSettings = async (eventId) => {
    const response = await getLightShowSettingsForEvent(eventId);
    if (response) {
      setSavedSettings(response);
    }
  };
  const renderSettingsDialog = (media, speed, hold, delay) => {
    return (
      <UvDialog
        show={showSettings}
        onClose={() => {
          setShowSettings(false);
        }}
        onSubmit={saveSettings}
        title="Settings"
        submitButtonLable="Save Settings"
      >
        {isLoading ? <Loader /> : null}
        <FormControl
          variant="standard"
          sx={{ width: "100%", marginTop: "20px" }}
        >
          <InputLabel htmlFor="option-font-size">
            Set Animation Speed
          </InputLabel>
          <Input
            id="option-font-size"
            type="number"
            inputProps={{ min: 1 }}
            value={speed}
            onChange={(e) => {
              const newValue = e.target.value;
              setAnimationSpeed(newValue);
            }}
          />
        </FormControl>
        <FormControl
          variant="standard"
          sx={{ width: "100%", marginTop: "20px" }}
        >
          <InputLabel htmlFor="option-font-size">Set Hold Duration</InputLabel>
          <Input
            id="option-font-size"
            type="number"
            inputProps={{ min: 1 }}
            value={hold}
            onChange={(e) => {
              const newValue = e.target.value;
              setHoldDuration(newValue);
            }}
          />
        </FormControl>
        <FormControl
          variant="standard"
          sx={{ width: "100%", marginTop: "20px" }}
        >
          <InputLabel htmlFor="option-font-size">Set Repeat Delay</InputLabel>
          <Input
            id="option-font-size"
            type="number"
            inputProps={{ min: 1 }}
            value={delay}
            onChange={(e) => {
              const newValue = e.target.value;
              setRepeatDelay(newValue);
            }}
          />
        </FormControl>
        <FormControlLabel
          sx={{ width: "100%", marginTop: "20px" }}
          control={
            <Checkbox
              checked={isAnimateLogo}
              onChange={(e) => {
                setIsAnimateLogo(e.target.checked);
              }}
              name="isAnimateLogo"
              color="primary"
            />
          }
          label="Animate Logo?"
        />
        <FormControl
          variant="standard"
          sx={{
            width: "100%",
            padding: "20px 0px",
            marginTop: "20px",
          }}
        >
          <Grid container spacing={2} columns={9}>
            <Grid item xs={7}>
              <div>
                <input
                  type="file"
                  id="background-image"
                  onChange={(event) => {
                    const file = event.target.files[0];
                    if (file) {
                      const reader = new FileReader();
                      reader.onloadend = () => {
                        const newImage = reader.result;
                        setSelectedMediaDataUrl(newImage);
                      };
                      reader.readAsDataURL(file);
                    }
                  }}
                  accept="image/*"
                  style={{ display: "none" }}
                />
                <label htmlFor="background-image" style={{ cursor: "pointer" }}>
                  <IconButton
                    component="span"
                    color="primary"
                    aria-label="upload"
                    size="large"
                  >
                    <Wallpaper />
                  </IconButton>
                  Upload Logo
                </label>
              </div>
            </Grid>
          </Grid>
          {media && (
            <>
              <img
                src={media}
                alt="Logo Preview"
                style={{
                  height: "100%",
                  width: "auto",
                  margin: "10px 0",
                  objectFit: "contain",
                }}
              />

              <Button
                fullWidth
                variant="outlined"
                onClick={() => {
                  setSelectedMediaDataUrl(null);
                }}
              >
                Remove Logo
              </Button>
            </>
          )}
        </FormControl>
      </UvDialog>
    );
  };
  const playEffect = (intensity, threshold, show) => {
    setIntensity((pre) => {
      return pre !== intensity ? intensity : pre;
    });
    setThreshold((pre) => {
      return pre !== threshold ? threshold : pre;
    });
    setShow((pre) => {
      // Make sure both objects have only non object values
      if (Object.values(pre).toString() !== Object.values(show).toString()) {
        return show;
      } else {
        return pre;
      }
    });
  };
  return (
    <>
      <div className="light-show-home">
        <div className="header">
          <div className="total">
            <h3>Light Shows: {totalShows}</h3>
          </div>

          <div className="actions">
            <Button
              variant="contained"
              disableElevation
              sx={{ mt: 1, mr: 1, mb: 1 }}
              onClick={addLightShow}
              className="add-light-show-button"
            >
              Add a Light Show
            </Button>
            <IconButton onClick={() => setShowSettings(true)} size="large">
              <Settings sx={{ color: "white" }} />
            </IconButton>
            {showList.length ? (
              <ConnectionManager
                onChange={(connectionStatus) => {
                  setIsConnected(connectionStatus);
                }}
              />
            ) : null}
          </div>
        </div>
        <div className="list">
          {showList.map((show, i) => (
            <div
              key={show._id}
              className="list-item"
              onClick={(e) => {
                const classes =
                  e.target.className && typeof e.target.className === "string"
                    ? e.target.className
                    : "";
                if (classes.includes("list-item")) {
                  setExpand(false);
                }
                setSelectedShow(show);
              }}
            >
              <div
                style={{
                  width: "100%",
                  display: "inline-flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Typography>{show.title}</Typography>
                <div>
                  <IconButton
                    onClick={(e) => {
                      e.stopPropagation();
                      if (selectedShow._id === show._id) {
                        setExpand((pre) => !pre);
                      } else {
                        setSelectedShow(show);
                        setExpand(true);
                      }
                    }}
                  >
                    <Tooltip title="Edit">
                      <Edit />
                    </Tooltip>
                  </IconButton>
                  <IconButton
                    onClick={() => {
                      setShowConfirmationDialog(true);
                    }}
                  >
                    <Tooltip title={"Delete"}>
                      <Delete />
                    </Tooltip>
                  </IconButton>
                </div>
              </div>
              {expand && show._id === selectedShow._id ? (
                <div style={{ marginTop: "12px" }}>
                  <LightShowDetails show={show} onUpdate={onLightShowUpdate} />
                </div>
              ) : null}
            </div>
          ))}
        </div>
        <div className="preview">
          <h3>Preview</h3>
          <LightShowPreview
            pulse={intensity > threshold}
            {...show}
            {...savedSettings}
          />
        </div>
      </div>
      <AddLightShow
        show={addShow}
        closeDialog={() => {
          setAddShow(false);
          getData();
        }}
      />
      <LightShowConsole
        allShows={showList}
        eventId={location.state.event.event._id}
        playEffect={playEffect}
        settings={savedSettings}
        key={savedSettings?._id}
      />
      {renderDeleteConfirmationDialog()}
      {renderSettingsDialog(
        selectedMediaDataUrl,
        animationSpeed,
        holdDuration,
        repeatDelay
      )}
    </>
  );
};
export default LightShowHome;
