export const TABS = {
  GAMES: "games",
  LIVE: "live",
  SOCIAL_FEED: "social_feed",
  SOCIAL_SEND: "social_send",
  SOCIAL: "social",
  LIGHT_SHOW: "light_show",
};

export const ONLINE_PRESENCE_INTERVAL = 2000;

export const LIBRARIES = ["places"]
