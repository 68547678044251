import io from "socket.io-client";
import {BASE_URL} from "./constants";
// "undefined" means the URL will be computed from the `window.location` object
const URL =
  process.env.NODE_ENV === "production" ? "https://dev-apiuvenu.uvenu.com" : BASE_URL;

  export const socket = io(URL, {
    withCredentials: false,
    autoConnect: false
  });
