import { socket } from "../../socket";
import { useEffect, useState } from "react";
import { Switch, FormControlLabel } from "@mui/material";
const ConnectionManager = ({ onChange }) => {
  const [isConnected, setIsConnected] = useState(socket.connected);
  const connect = () => {
    socket.connect();
  };

  const disconnect = () => {
    socket.disconnect();
  };
  const onConnect = () => {
    setIsConnected(true);
  };

  const onDisconnect = () => {
    setIsConnected(false);
  };

  const logConnectionErros = (err) => {
    // the reason of the error, for example "xhr poll error"
    console.log(err.message);
    // some additional description, for example the status code of the initial HTTP response
    console.log(err.description);
    // some additional context, for example the XMLHttpRequest object
    console.log(err.context);
  };

  useEffect(() => {
    socket.on("connect_error", logConnectionErros);
    socket.on("connect", onConnect);
    socket.on("disconnect", onDisconnect);

    return () => {
      socket.off("connect", onConnect);
      socket.off("disconnect", onDisconnect);
      socket.off("connect_error", logConnectionErros);
      disconnect();
    };
  }, []);
  useEffect(() => {
    onChange(isConnected);
  }, [isConnected]);
  const handleChange = (event) => {
    event.target.checked ? connect() : disconnect();
  };
  return (
    <FormControlLabel
      sx={{ ml: 1 }}
      control={
        <Switch
          checked={isConnected}
          onChange={handleChange}
          inputProps={{ "aria-label": "controlled" }}
          color="success"
        />
      }
      label={isConnected ? "Stop Streaming" : "Start Streaming"}
    />
  );
};
export default ConnectionManager;
