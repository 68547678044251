import { useEffect, useMemo, useRef, useState } from "react";
import "./FlippingQuoteCard.css";
import { motion } from "framer-motion";
import { LAYOUT_ORIENTATION_MAPPING } from "../../../utils/defaultSettings";

import FlipCardInner from "./FlipCardInner";
const FlippingQuoteCard = ({
  m1,
  m2,
  flipId,
  orientation,
  onRequestNewMeida,
  borderWidth,
  roundedCorners = true,
  className,
  cardWidth,
  showQuoteIcon = false,
  cardStyle = { id: 1, color: "#88A28D", bgImage: null, contentColor: "#fff" },
  mediaItemRotateBy,
  mediaItemX,
  mediaCardScale,
  quoteFontSize,
  font
}) => {
  const [media1Data, setMedia1Data] = useState(m1);
  const [media2Data, setMedia2Data] = useState(m2);
  const [isFlipped, setIsFlipped] = useState(false);

  const flipCardInnerRef = useRef(null);

  const getBackTurnedCardMediaSetter = () => {
    const elem = flipCardInnerRef.current;
    if (elem) {
      var style = window.getComputedStyle(elem);
      var matrix = new window.WebKitCSSMatrix(style.transform);
      if (Math.sign(matrix.a) === -1) {
        return setMedia1Data;
      } else {
        return setMedia2Data;
      }
    }
  };

  const requestNewMediaCb = (newMeida) => {
    if (newMeida && newMeida.mediaFileUrl) {
      const mediaCardDataSetter = getBackTurnedCardMediaSetter();
      mediaCardDataSetter(newMeida);
    }
  };

  const triggerFlip = () => {
    setIsFlipped((pre) => !pre);
    onRequestNewMeida(requestNewMediaCb, orientation);
  };
  useEffect(() => {
    if (flipId === m1?._id + m2?._id) {
      triggerFlip();
    }
  }, [m1, m2, flipId]);

  const getEnableBlur = (className, orientation, data) => {
    let enable = false;
    const mapItem = className.includes("card-grid")
      ? "portrait"
      : LAYOUT_ORIENTATION_MAPPING().get(className);
    const mediaAspectRatio = data.width / data.height;
    const innerCardAspectRatio = orientation === "portrait" ? 9 / 16 : 16 / 9;
    const diff = mediaAspectRatio.toFixed(2) - innerCardAspectRatio.toFixed(2);
    if (mapItem !== orientation) {
      enable = true;
    } else if (diff !== 0) {
      enable = true;
    }
    return enable;
  };



  return (
    <div className={`flip-card-quote ${className}`} style={{fontFamily:font}}>
      <motion.div
        className={`flip-quote-card-inner`}
        initial={false}
        animate={{ rotateY: isFlipped ? 180 : 360 }}
        transition={{ duration: 0.6, animationDirection: "normal" }}
        ref={flipCardInnerRef}
      >
        <FlipCardInner
          isFront={true}
          data={media1Data}
          styles={cardStyle}
          key="front"
          cardWidth={cardWidth}
          mediaItemRotateBy={mediaItemRotateBy}
          mediaItemX={mediaItemX}
          mediaCardScale={mediaCardScale}
          borderWidth={borderWidth}
          enableBlur={getEnableBlur(className, orientation, media1Data)}
          showQuoteIcon={showQuoteIcon}
          roundedCorners={roundedCorners}
          quoteFontSize={quoteFontSize}
        />
        <FlipCardInner
          data={media2Data}
          styles={cardStyle}
          key="back"
          cardWidth={cardWidth}
          mediaItemRotateBy={mediaItemRotateBy}
          mediaItemX={mediaItemX}
          mediaCardScale={mediaCardScale}
          borderWidth={borderWidth}
          enableBlur={getEnableBlur(className, orientation, media2Data)}
          showQuoteIcon={showQuoteIcon}
          roundedCorners={roundedCorners}
          quoteFontSize={quoteFontSize}
        />
      </motion.div>
    </div>
  );
};
export default FlippingQuoteCard;
