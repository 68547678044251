import React, { useContext, useState, useEffect } from "react";

import { useLocation } from "react-router-dom";
import { URLS } from "../../constants";
import Layout from "../../Layout";

import { uvenuFetcher } from "../../utils/uvenu-fetcher";

import { connect } from "redux-zero/react";
import Sidebar from "./sidebar/SideBarLayout";
import EventInteractionControl from "../Games/EventIneractionControl";
import actions from "../../store/actions";

import { insights } from "../../ApplicationInsightsService";
import Metrics from "../Metrics";
import { globals } from "./globals";
import LiveUsers from "./LiveUsers";
import { AppContext } from "../../contexts/App.Context";
import SocialOperator from "../Social/SocialOperator";
import QuestionnaireControlScreen from "../Games/QuestionnaireControlScreen";
import LightShowHome from "../LightShow/LightShowHome";

const mapStateToProps = (state) => {
  return {
    loggedInUser: state.currentUser,
  };
};
export default connect(
  mapStateToProps,
  actions
)(function (props) {
  const appContext = useContext(AppContext);
  const { loggedInUser: loggedInUserObj } = props;
  const { setUserCounts } = props;
  const location = useLocation();
  const eventDetails = location.state.event;
  props.setEventId(eventDetails.event._id);

  globals.updateUserCounts = () => {
    getOnlineUserCounts();
  };

  async function getOnlineUserCounts() {
    try {
      if (loggedInUserObj === null) return;

      const response = await uvenuFetcher({
        method: "GET",
        url: URLS.EVENT_USERS_COUNTS(eventDetails.event._id),
        headers: {
          token: `${loggedInUserObj.token}`,
        },
      });
      if (response.statusCode === 200) {
        setUserCounts(response.json.data);
      } else {
        let error = Error(response.json.message);
        insights.trackUvenuException(error, {
          info: "Getting users counts - failed",
          adminUserId: loggedInUserObj.email,
          adminSessionId: globals.adminSessionId,
          message: response.json.message,
        });
      }
    } catch (error) {
      insights.trackUvenuException(error, {
        status: "error",
        info: "Exception in Getting users counts",
        error: error,
        adminSessionId: globals.adminSessionId,
      });
    }
  }

  const updateEvent = async (status, forOverlay) => {
    const payload = {
      eventId: eventDetails.event._id,
    };
    if (forOverlay) payload.brandingOverlayImageEnabled = status;
    else payload.userStreamsAllowed = status;
    try {
      const response = await uvenuFetcher({
        method: "PATCH",
        url: URLS.EDIT_EVENT,
        body: payload,
        headers: {
          token: `${eventDetails.token}`,
        },
      });
      if (response.statusCode === 200) {
        appContext.triggerToast(true, {
          type: "success",
          message: "Updated successfully.",
        });
      } else {
        appContext.triggerToast(true, {
          type: "error",
          message: response.json.message,
        });
        if (forOverlay) setOverlayCheckedStatus(!status);
        else setAllowUsersCheckedStatus(!status);
        let error = Error(response.json.message);
        insights.trackUvenuException(error, {
          status: "failed",
          info: "Error in updating overlay setting change for event",
          message: response.json.message,
          adminUserId: loggedInUserObj.email,
          adminSessionId: globals.adminSessionId,
        });
      }
    } catch (error) {
      insights.trackUvenuException(error, {
        status: "error",
        info: "Unhandled exception in overlay setting change",
        error: error,
        adminUserId: loggedInUserObj.email,
        adminSessionId: globals.adminSessionId,
      });
    }
  };

  useEffect(() => {
    props.setLoggedInUser(loggedInUserObj);
  });

  let [allowUsersCheckedStatus, setAllowUsersCheckedStatus] = useState(
    eventDetails.event.userStreamsAllowed
  );
  function allowUsersStatusChanged(status) {
    setAllowUsersCheckedStatus(status);
    updateEvent(status, false);
  }

  let allowUsersData = {
    allowUsersStatusChanged: allowUsersStatusChanged,
    checkedStatus: allowUsersCheckedStatus,
  };

  let [overlayCheckedStatus, setOverlayCheckedStatus] = useState(
    eventDetails.event.brandingOverlayImageEnabled
  );
  function overlayStatusChanged(status) {
    setOverlayCheckedStatus(status);
    updateEvent(status, true);
  }

  let overlayData = {
    overlayStatusChanged: overlayStatusChanged,
    checkedStatus: overlayCheckedStatus,
  };

  const [eventInteraction, setEventInteraction] = useState(false);
  const handleEventInteraction = () => {
    resetToggleValues();
    setEventInteraction(true);
  };

  let eventInteractionBtn = {
    handleEventInteraction: handleEventInteraction,
    selected: eventInteraction,
  };

  const resetToggleValues = () => {
    setUserScreenToggle(false);
    setProdUserScreenToggle(false);
    setEventInteraction(false);
    setSocialOperator(false);
    setGamify(false);
    setLightshow(false);
    setMetricsScreenToggle(false);
  };
  const [userScreenToggle, setUserScreenToggle] = useState(true);
  const [prodUserScreenToggle, setProdUserScreenToggle] = useState(false);
  const handleUserScreenToggle = () => {
    resetToggleValues();
    setUserScreenToggle(true);
  };
  const handleProdUserScreenToggle = () => {
    resetToggleValues();
    setProdUserScreenToggle(true);
  };
  let userScreenToggleBtn = {
    handleUserScreenToggle: handleUserScreenToggle,
    selected: userScreenToggle,
    productionSelected: prodUserScreenToggle,
    handleProdUserScreenToggle: handleProdUserScreenToggle,
  };

  const [socialOperator, setSocialOperator] = useState(false);
  const handleSocialOperator = () => {
    resetToggleValues();
    setSocialOperator(true);
  };

  let socialOperatorBtn = {
    handleSocialOperator: handleSocialOperator,
    selected: socialOperator,
  };

  const [gamify, setGamify] = useState(false);
  const handleGamify = () => {
    resetToggleValues();
    setGamify(true);
  };

  let gamifyBtn = {
    handleGamify: handleGamify,
    selected: gamify,
  };

  const [lightshow, setLightshow] = useState(false);
  const handleLightshow = () => {
    resetToggleValues();
    setLightshow(true);
  };

  let lightshowBtn = {
    handleLightshow: handleLightshow,
    selected: lightshow,
  };

  const [metricsScreenToggle, setMetricsScreenToggle] = useState(false);
  const handleMetricsScreenToggle = () => {
    resetToggleValues();
    setMetricsScreenToggle(true);
  };

  let metricsScreenToggleBtn = {
    handleMetricsScreenToggle: handleMetricsScreenToggle,
    selected: metricsScreenToggle,
  };

  return (
    <Sidebar
      event={eventDetails.event}
      allowUsersData={allowUsersData}
      overlayData={overlayData}
      eventInteractionBtn={eventInteractionBtn}
      userScreenToggleBtn={userScreenToggleBtn}
      socialOperatorBtn={socialOperatorBtn}
      gamifyBtn={gamifyBtn}
      lightshowBtn={lightshowBtn}
      metricsScreenToggleBtn={metricsScreenToggleBtn}
    >
      <Layout title={eventDetails.event.name}>
        {eventInteraction ? <EventInteractionControl /> : null}
        {userScreenToggle || prodUserScreenToggle ? (
          <LiveUsers
            event={eventDetails.event}
            usersType={userScreenToggle ? "live" : "prod"}
          />
        ) : null}
        {socialOperator ? <SocialOperator /> : null}
        {gamify ? <QuestionnaireControlScreen /> : null}
        {lightshow ? <LightShowHome /> : null}
        {metricsScreenToggle ? <Metrics event={eventDetails.event} /> : null}
      </Layout>
    </Sidebar>
  );
});
