import React, { useState, useRef, useEffect, useContext } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Typography,
  Link,
  IconButton,
  DialogContentText,
  TextField,
  Select,
  MenuItem,
} from "@mui/material";
import Webcam from "react-webcam";

import "./styles.css";
import send_camera from "../../../../src/assets/send_camera.png";
import record_icon from "../../../../src/assets/record-icon.png";
import camera_switch from "../../../../src/assets/camera_switch.png";
import Loader from "../../../components/Loader/Loader";

import generateUserid from "../../../utils/generateUserid";
import { URLS } from "../../../constants";
import { uploader } from "../../../utils/uploader";
import muted from "../../../../src/assets/muted.png";
import unmuted from "../../../../src/assets/unmuted.png";
import {
  extractPoster,
  formatHyperlink,
  getPlatformInfo,
  isIosTypeSupported,
} from "../../../utils/utility";

import {
  FacebookShareButton,
  FacebookIcon,
  TwitterShareButton,
  XIcon,
} from "react-share";
import { AddPhotoAlternate, MoreHorizRounded } from "@mui/icons-material";
import { AD_TYPES } from "../../AdsSetupManager/functions";
import {
  getEnableSocialSharingLinks,
  getGalleryEnabled,
  getHashTags,
  getRecordingEnabled,
  getStillsEnabled,
  getVideoDuration,
} from "../../../components/SocialSubmissionSettings/functions";
import { COLORS } from "../../../utils/colors";
import { AppContext } from "../../../contexts/App.Context";
import {
  dataURItoBlob,
  getFilename,
  loadFFmpegLibrary,
  openNativeShareControl,
} from "../../../utils/mediaFunctions";
import RecordRTC from "recordrtc";

let updateSubmissionCloseButton = () => {};
export function SocialSend(props) {
  const {
    userId,
    userName,
    eventData,
    loadwithoptions,
    isPortrait,
    advertsData,
  } = props;
  const appContext = useContext(AppContext);
  const [isLoading, setIsLoading] = useState(false);
  const [cameraActive, setCameraActive] = useState(true);
  const [capturedPhoto, setCapturedPhoto] = useState(null);
  const [recordedVideo, setRecordedVideo] = useState(null);
  const [recording, setRecording] = useState(false);
  const recordRTCRef = useRef(null);
  const webCamRef = useRef(null);
  const [camerasList, setCamerasList] = useState([]);
  const [selectedCameraDeviceId, setSelectedCameraDeviceId] = useState(null);
  const [selectedCameraMode, setSelectedCameraMode] = useState("user");
  const [selectedCameraConfig, setSelectedCameraConfig] = useState({
    width: 1280,
    height: 720,
  });
  const [coordinates, setCoordinates] = useState([0, 0]);
  const [mediaDescription, setMediaDescription] = useState("");
  const [uploadedMedia, setUploadedMedia] = useState(null);
  const [showPreviewAd, setShowPreviewAd] = useState(false);
  const [socialSubmissionAd, setSocialSubmissionAd] = useState(null);

  //Boolean state handling whether ffmpeg has loaded or not
  const [ready, setReady] = useState(false);
  //Ref to handle the current instance of ffmpeg when loaded
  const ffmpeg = useRef(null);

  let userID = useRef(userId);
  useEffect(() => {
    if (userID.current === "" || userID.current === null) {
      const databaseUniqueUserID = localStorage.getItem("databaseUniqueUserID");
      if (databaseUniqueUserID) {
        userID.current = databaseUniqueUserID;
      } else {
        userID.current = generateUserid();
      }
    }

    navigator.geolocation.getCurrentPosition(
      (position) => {
        setCoordinates([position.coords.longitude, position.coords.latitude]);
      },
      (error) => {
        setCoordinates([0, 0]);
      }
    );
    // loadFFmpeg();
  }, []);

  const loadFFmpeg = async () => {
    ffmpeg.current = await loadFFmpegLibrary();
    // console.log("ffmpeg loaded successfully", ffmpeg.current);
    setReady(true);
  };

  useEffect(() => {
    if (advertsData === null || advertsData === undefined) return;
    let newAdverts = advertsData.filter(
      (a) => a.adType == AD_TYPES.SOCIAL_SUBMISSION_AD
    );
    if (newAdverts.length > 0) setSocialSubmissionAd(newAdverts[0]);
  }, [advertsData]);

  const handlePhotoClick = async () => {
    try {
      const photo = await capturePhoto();
      setCapturedPhoto(photo);
      setCameraActive(false);
    } catch (error) {
      console.error("Error capturing photo:", error);
    }
  };

  const capturePhoto = () => {
    return new Promise((resolve, reject) => {
      const imageURI = webCamRef.current.getScreenshot({
        width: webCamRef.current.video.videoWidth,
        height: webCamRef.current.video.videoHeight,
      });
      const imageBlob = dataURItoBlob(imageURI);
      const photo = {
        id: Date.now(),
        type: "photo",
        timestamp: new Date().toLocaleString(),
        imageBlob: imageBlob,
        imageURI: imageURI,
      };
      resolve(photo);
    });
  };

  const handleRecordVideoClick = () => {
    if (
      recordRTCRef.current &&
      recordRTCRef.current.getState() === "recording"
    ) {
      stopVideoRecording();
    } else {
      startVideoRecording();
    }
  };

  const startVideoRecording = () => {
    const mediaStream = webCamRef.current.stream;
    if (!mediaStream) {
      // MediaStream is not available.
      appContext.triggerToast(true, {
        type: "warning",
        message: "recorder is not ready",
      });
      return;
    }

    setRecording(true);
    recordRTCRef.current = new RecordRTC(mediaStream, {
      type: "video",
      mimeType: "video/webm;codecs=vp8",
    });
    recordRTCRef.current.startRecording();

    // Stop recording after config seconds
    setTimeout(() => {
      if (
        recordRTCRef.current &&
        recordRTCRef.current.getState() === "recording"
      ) {
        stopVideoRecording();
      }
    }, getVideoDuration(eventData.socialSubmissionSettings) * 1000);
  };

  const stopVideoRecording = () => {
    try {
      recordRTCRef.current.stopRecording(() => {
        const blob = recordRTCRef.current.getBlob();
        const file = new File([blob], "mediaFile.mp4", {
          type: "video/mp4",
        });
        const videoUrl = URL.createObjectURL(blob);
        const video = {
          id: Date.now(),
          type: "video",
          timestamp: new Date().toLocaleString(),
          videoUrl,
          size: blob.size,
          videoBlob: file,
          isRecorded: true,
        };
        setTimeout(() => {
          setCameraActive(false); // Switch off the camera after stopping the recording
          setRecording(false); // Update the recording state
          setRecordedVideo(video);
        }, 1000);
      });
    } catch (error) {
      console.error("Error stopping video recording:", error);
    }
  };

  async function uploadMedia() {
    setIsLoading(true);
    let userLocation = { type: "Point", coordinates: coordinates };
    const payload = {
      eventId: eventData._id,
      userId: userName,
      comment: mediaDescription,
      userLocation: JSON.stringify(userLocation),
    };
    if (capturedPhoto) {
      payload["mediaFile"] = capturedPhoto.imageBlob;
    } else {
      if (recordedVideo?.isRecorded) {
        payload["isIosTypeSupported"] = isIosTypeSupported();
        payload["isRecorded"] = recordedVideo?.isRecorded;
      }

      if (recordedVideo.videoPosterUrl) {
        if (getPlatformInfo().isIOS || getPlatformInfo().isMac) {
          if (!recordedVideo?.isRecorded) {
            payload["videoPosterUrl"] = recordedVideo.videoPosterUrl;
          }
        } else {
          payload["videoPosterUrl"] = recordedVideo.videoPosterUrl;
        }
      }

      payload["mediaFile"] = recordedVideo.videoBlob;
    }

    const uploadResponse = await uploader({
      url: URLS.UPLOAD_MEDIA(),
      body: payload,
    });
    setUploadedMedia(uploadResponse.json.sharedMedia);
    setIsLoading(false);
    setShowPreviewAd(true);
    setTimeout(() => {
      updateSubmissionCloseButton(true);
    }, getSubmissionAdTimerValue() * 1000);
  }

  function discardMedia() {
    setCapturedPhoto(null);
    setRecordedVideo(null);
    setRecording(false);
    setCameraActive(true);
    setShowPreviewAd(false);
    updateSubmissionCloseButton(false);
  }

  const updateCameraList = (wcstream) => {
    navigator.mediaDevices.getUserMedia({ audio: true }).then((stream) => {
      if (webCamRef.current && wcstream && stream) {
        webCamRef.current.stream = new MediaStream([
          ...wcstream.getTracks(),
          ...stream.getTracks(),
        ]);
      }
    });

    navigator.mediaDevices.enumerateDevices().then((devices) => {
      const videoCameras = devices.filter(
        (d) => d.kind === "videoinput" && d.deviceId.length > 0
      );
      if (videoCameras.length > 0) {
        setCamerasList(videoCameras);
        setSelectedCameraDeviceId(videoCameras[0].deviceId);
        setSelectedCameraMode("user");
        if (loadwithoptions === "true") {
          setSelectedCameraConfig({
            width: 1280,
            height: 720,
            deviceId: selectedCameraDeviceId,
          });
        } else {
          setSelectedCameraConfig({
            width: 1280,
            height: 720,
            facingMode: selectedCameraMode,
          });
        }
      }
    });
  };

  const onCameraChange = (event) => {
    if (loadwithoptions === "true") {
      setSelectedCameraDeviceId(event.target.value);
      setSelectedCameraConfig({
        width: 1280,
        height: 720,
        deviceId: event.target.value,
      });
    } else {
      setSelectedCameraMode(
        selectedCameraMode === "environment" ? "user" : "environment"
      );
      setSelectedCameraConfig({
        width: 1280,
        height: 720,
        facingMode:
          selectedCameraMode === "environment" ? "user" : "environment",
      });
    }
  };

  function RenderSocialSubmissionAd({ uploadedMedia }) {
    const [socialSubmissionAdMuted, setSocialSubmissionAdMuted] =
      useState(true);
    const [showPreviewAdClose, setShowPreviewAdClose] = useState(false);

    updateSubmissionCloseButton = (status) => {
      setShowPreviewAdClose(status);
    };

    const getHashTagsForSharing = (forFacebook) => {
      let hashTags = getHashTags(eventData.socialSubmissionSettings);
      hashTags = hashTags.split(" ");
      if (forFacebook) {
        hashTags = hashTags.map((tag) => "#" + tag);
        hashTags = hashTags.join(" ");
      }
      return hashTags;
    };

    return (
      <>
        {socialSubmissionAd !== null && (
          <div
            className="socialSubmissionAd"
            style={{
              borderColor:
                eventData && eventData.brandingBackgroundColor
                  ? eventData.brandingBackgroundColor
                  : COLORS.appBackground,
            }}
          >
            <Link
              href={formatHyperlink(socialSubmissionAd.adLink)}
              target="_blank"
              underline="hover"
            >
              {socialSubmissionAd.mimeType.includes("video") ? (
                <>
                  <video
                    style={{
                      width: "100%",
                      // height: 182,
                    }}
                    src={socialSubmissionAd.adMediaUrl}
                    autoPlay
                    playsInline
                    loop
                    muted={socialSubmissionAdMuted}
                    disableRemotePlayback={true}
                  />
                  <Button
                    className="jelscMuteIcon"
                    onClick={(e) => {
                      setSocialSubmissionAdMuted(!socialSubmissionAdMuted);
                      e.preventDefault();
                    }}
                  >
                    <img
                      style={{
                        width: 30,
                        height: 30,
                      }}
                      src={socialSubmissionAdMuted ? unmuted : muted}
                      alt="mute"
                    />
                  </Button>
                </>
              ) : (
                <img
                  src={socialSubmissionAd.adMediaUrl}
                  alt="social ad media"
                  className="capturedPhoto"
                  // style={{ height: isPortrait ? 300 : 100 }}
                />
              )}
              <div
                className="jelscActionBar"
                style={{
                  marginTop: socialSubmissionAd.mimeType.includes("video")
                    ? -25
                    : -5,
                  backgroundColor:
                    eventData && eventData.brandingBackgroundColor
                      ? eventData.brandingBackgroundColor
                      : COLORS.appBackground,
                }}
              >
                <span className="adTitle">
                  {socialSubmissionAd.adText
                    ? socialSubmissionAd.adText
                    : "Sponsored Ad"}
                </span>
                <span className="adAction">{">"}</span>
              </div>
            </Link>
          </div>
        )}
        {showPreviewAdClose && (
          <div
            style={{
              marginTop: 10,
              width: "100%",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            {getEnableSocialSharingLinks(
              eventData.socialSubmissionSettings
            ) && (
              <div
                style={{
                  marginTop: 10,
                  width: "100%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <FacebookShareButton
                  url={uploadedMedia.mediaFileUrl}
                  style={{ marginRight: 10 }}
                  hashtag={getHashTagsForSharing(true)}
                  media={uploadedMedia.mediaFileUrl}
                >
                  <FacebookIcon size={32} round />
                </FacebookShareButton>

                <TwitterShareButton
                  url={uploadedMedia.mediaFileUrl}
                  style={{ marginRight: 10 }}
                  hashtags={getHashTagsForSharing(false)}
                >
                  <XIcon size={32} round />
                </TwitterShareButton>

                <IconButton
                  size="32px"
                  onClick={() => {
                    openNativeShareControl(
                      getHashTagsForSharing(true),
                      uploadedMedia.mediaFileUrl
                    );
                  }}
                >
                  <MoreHorizRounded />
                </IconButton>
              </div>
            )}
            <Button
              style={{
                marginTop: 10,
                backgroundColor: "grey",
                width: "100%",
                height: "3rem",
              }}
              variant="contained"
              onClick={() => {
                discardMedia();
              }}
              disabled={isLoading}
            >
              CLOSE
            </Button>
          </div>
        )}
      </>
    );
  }

  const getSubmissionAdTimerValue = () => {
    if (socialSubmissionAd) {
      if (socialSubmissionAd.mimeType.includes("video")) return 10;
      else return 5;
    } else {
      return 0.1;
    }
  };

  const inputRef = useRef(null);
  const videoPreviewRef = useRef(null);
  const openGallery = () => {
    inputRef.current.click();
  };
  const handleImageChange = (event) => {
    const file = event.target.files[0];
    if (
      file &&
      (file.type.startsWith("image") || file.type.startsWith("video"))
    ) {
      // console.log("size ", file.size / 1024, "KB");
      if (file.size / 1024 > 1024 * 5) {
        appContext.triggerToast(true, {
          type: "error",
          message: "Please select a file less than 5MB.",
        });
      } else {
        if (file.type.startsWith("image")) {
          let reader = new FileReader();
          reader.readAsDataURL(file);
          reader.onloadend = () => {
            const imageBlob = dataURItoBlob(reader.result);
            const photo = {
              id: Date.now(),
              type: "photo",
              timestamp: new Date().toLocaleString(),
              imageBlob: imageBlob,
              imageURI: reader.result,
            };
            setCapturedPhoto(photo);
          };
        } else {
          const videoUrl = URL.createObjectURL(file);
          const videoElement = document.createElement("video");
          videoElement.src = videoUrl;
          videoElement.onloadeddata = async (e) => {
            if (videoElement.duration > 15) {
              appContext.triggerToast(true, {
                type: "error",
                message: "Please select a video less than 15 seconds.",
              });
            } else {
              e.target.currentTime = 0;
              const videoPosterUrl = await extractPoster(e.target);
              const video = {
                id: Date.now(),
                type: "video",
                timestamp: new Date().toLocaleString(),
                videoUrl,
                size: file.size,
                videoBlob: file,
                videoPosterUrl,
              };
              setRecordedVideo(video);
            }
          };
        }
      }
    } else {
      // Handle case where selected file is not an image
      appContext.triggerToast(true, {
        type: "error",
        message: "Please select an image file.",
      });
    }
  };

  return (
    <div className="jelscContainer">
      <Webcam
        ref={webCamRef}
        className="webcamPreview"
        audio={false}
        muted
        videoConstraints={selectedCameraConfig}
        audioConstraints={{ echoCancellation: true }}
        screenshotFormat="image/jpeg"
        screenshotQuality={1}
        forceScreenshotSourceSize={true}
        onUserMedia={(wcstream) => {
          updateCameraList(wcstream);
        }}
      />
      {isPortrait && (
        <>
          <div
            style={{
              position: "absolute",
              top: 0,
              zIndex: 999,
              width: "100%",
              height: "25%",
              backgroundColor: "rgba(0,0,0,0.5)",
            }}
          />
          <div
            style={{
              position: "absolute",
              zIndex: 999,
              bottom: 0,
              width: "100%",
              height: "25%",
              backgroundColor: "rgba(0,0,0,0.5)",
              display: "flex",
              flexDirection: "column-reverse",
              alignItems: "center",
            }}
          >
            {/* <Typography
              style={{
                marginBottom: 100,
                color: "white",
              }}
            >
              Fit your face in the frame for better shots
            </Typography> */}
          </div>
        </>
      )}
      {camerasList.length > 1 &&
        (loadwithoptions === "true" ? (
          <Select
            variant="standard"
            className="cameraButton"
            style={{ backgroundColor: "white" }}
            sx={{ color: "#0D1051" }}
            value={selectedCameraDeviceId}
            id="effect-select"
            onChange={onCameraChange}
          >
            {camerasList.map((camera) => (
              <MenuItem key={camera.deviceId} value={camera.deviceId}>
                {camera.label}
              </MenuItem>
            ))}
          </Select>
        ) : (
          <IconButton
            className="cameraButton"
            value="camera_switch"
            onClick={() => onCameraChange()}
            size="large"
          >
            <img
              src={camera_switch}
              alt="camera_switch"
              style={{ width: 40, height: 40, objectFit: "contain" }}
            />
          </IconButton>
        ))}

      {capturedPhoto ? (
        <Dialog
          open={!!capturedPhoto}
          PaperProps={{ style: { paddingTop: 0, borderRadius: 15 } }}
        >
          <DialogContentText className="jelscDialogHeader">
            <h3
              className="jelscDialogHeader"
              style={{ fontSize: showPreviewAd ? 18 : 22 }}
            >
              {showPreviewAd
                ? "Uploading your content!\nThanks for your submission."
                : "PREVIEW"}
            </h3>
          </DialogContentText>
          <DialogContent>
            {showPreviewAd ? (
              <RenderSocialSubmissionAd uploadedMedia={{ ...uploadedMedia }} />
            ) : (
              <div
                className="jelscMediaPreview"
                // style={{ height: isPortrait ? 300 : 100 }}
              >
                <img
                  key={capturedPhoto.id}
                  src={capturedPhoto.imageURI}
                  alt="Captured_Photo"
                  className="capturedPhoto"
                  style={{ height: isPortrait ? 300 : 100 }}
                />
                <TextField
                  style={{
                    color: "white",
                    backgroundColor: "transparent !important",
                    "& .MuiOutlinedInput-root": {
                      "& fieldset": {
                        borderColor: "grey",
                      },
                      "&:hover fieldset": {
                        borderColor: "grey",
                      },
                      "&.Mui-focused fieldset": {
                        borderColor: "grey",
                      },
                    },
                  }}
                  variant="outlined"
                  onInput={(e) => {}}
                  defaultValue={mediaDescription}
                  margin="dense"
                  id="description"
                  label="Media Description (Optional)"
                  multiline={true}
                  InputLabelProps={{ style: { color: "black" } }}
                  fullWidth
                  onChange={(e) => {
                    setMediaDescription(e.target.value);
                  }}
                />
                {isLoading && (
                  <div
                    style={{
                      position: "absolute",
                      alignSelf: "center",
                      zIndex: 999,
                    }}
                  >
                    <Loader />
                  </div>
                )}
              </div>
            )}
          </DialogContent>
          {showPreviewAd === false && (
            <Typography className="jelscDialogText">
              {"By continuing you agree to\nUvenu LLC’s "}
              <Link
                style={{ color: "blue" }}
                href={"https://www.uvenu.com/uvenu-terms-and-conditions"}
                target="_blank"
                underline="hover"
              >
                {" "}
                Privacy Policy{" "}
              </Link>
              {" and "}
              <Link
                style={{ color: "blue" }}
                href={"https://www.uvenu.com/uvenu-terms-and-conditions"}
                target="_blank"
                underline="hover"
              >
                {" "}
                Terms{" "}
              </Link>
              {"."}
            </Typography>
          )}
          <DialogActions className="jelscDialogActions">
            {showPreviewAd === false && (
              <Button
                style={{
                  backgroundColor: "red",
                  margin: 4,
                  width: "100%",
                  height: "3rem",
                }}
                variant="contained"
                onClick={() => {
                  discardMedia();
                }}
                disabled={isLoading}
              >
                RETAKE
              </Button>
            )}
            {showPreviewAd === false && (
              <Button
                style={{
                  backgroundColor: "green",
                  margin: 4,
                  width: "100%",
                  height: "3rem",
                }}
                variant="contained"
                onClick={() => {
                  uploadMedia();
                }}
                disabled={isLoading || showPreviewAd}
              >
                UPLOAD
              </Button>
            )}
          </DialogActions>
        </Dialog>
      ) : recordedVideo ? (
        <Dialog
          open={!!recordedVideo}
          PaperProps={{ style: { paddingTop: 0, borderRadius: 15 } }}
        >
          <DialogContentText className="jelscDialogHeader">
            <h3
              className="jelscDialogHeader"
              style={{ fontSize: showPreviewAd ? 18 : 22 }}
            >
              {showPreviewAd
                ? "Uploading your content!\nThanks for your submission."
                : "PREVIEW"}
            </h3>
            {/* {isLoading && !recordedVideo.videoBlob.type.includes("mp4") && (
              <Typography
                className="jelscDialogText"
                style={{
                  color: COLORS.error,
                  fontWeight: "bold",
                  fontSize: 12,
                }}
              >
                Converting video to a compatible format. Please wait...
              </Typography>
            )} */}
          </DialogContentText>
          <DialogContent>
            {showPreviewAd ? (
              <RenderSocialSubmissionAd uploadedMedia={{ ...uploadedMedia }} />
            ) : (
              <div
                className="jelscMediaPreview"
                // style={{ height: isPortrait ? 300 : 100 }}
              >
                <video
                  style={{ width: "100%", height: isPortrait ? 300 : 100 }}
                  ref={videoPreviewRef}
                  src={recordedVideo.videoUrl}
                  autoPlay
                  playsInline
                  loop
                  muted={false}
                  disableRemotePlayback={true}
                  onLoadedData={async (e) => {
                    e.target.currentTime = 0;
                    const videoPosterUrl = await extractPoster(e.target);
                    setRecordedVideo((pre) => ({ ...pre, videoPosterUrl }));
                  }}
                />
                <TextField
                  style={{
                    color: "white",
                    backgroundColor: "transparent !important",
                    "& .MuiOutlinedInput-root": {
                      "& fieldset": {
                        borderColor: "grey",
                      },
                      "&:hover fieldset": {
                        borderColor: "grey",
                      },
                      "&.Mui-focused fieldset": {
                        borderColor: "grey",
                      },
                    },
                  }}
                  variant="outlined"
                  onInput={(e) => {}}
                  defaultValue={mediaDescription}
                  margin="dense"
                  id="description"
                  label="Media Description (Optional)"
                  multiline={true}
                  InputLabelProps={{ style: { color: "black" } }}
                  fullWidth
                  onChange={(e) => {
                    setMediaDescription(e.target.value);
                  }}
                />
                {isLoading && (
                  <div
                    style={{
                      position: "absolute",
                      alignSelf: "center",
                      zIndex: 999,
                    }}
                  >
                    <Loader />
                  </div>
                )}
              </div>
            )}
          </DialogContent>
          {showPreviewAd === false && (
            <Typography className="jelscDialogText">
              {"By continuing you agree to\nUvenu LLC’s "}
              <Link
                style={{ color: "blue" }}
                href={"https://www.uvenu.com/uvenu-terms-and-conditions"}
                target="_blank"
                underline="hover"
              >
                {" "}
                Privacy Policy{" "}
              </Link>
              {" and "}
              <Link
                style={{ color: "blue" }}
                href={"https://www.uvenu.com/uvenu-terms-and-conditions"}
                target="_blank"
                underline="hover"
              >
                {" "}
                Terms{" "}
              </Link>
              {"."}
            </Typography>
          )}
          <DialogActions className="jelscDialogActions">
            {showPreviewAd === false && (
              <Button
                style={{
                  backgroundColor: "red",
                  margin: 4,
                  width: "100%",
                  height: "3rem",
                }}
                variant="contained"
                onClick={() => {
                  discardMedia();
                }}
                disabled={isLoading}
              >
                RETAKE
              </Button>
            )}
            {showPreviewAd === false && (
              <Button
                style={{
                  backgroundColor: "green",
                  margin: 4,
                  width: "100%",
                  height: "3rem",
                }}
                variant="contained"
                onClick={() => {
                  uploadMedia();
                }}
                disabled={isLoading || showPreviewAd}
              >
                UPLOAD
              </Button>
            )}
          </DialogActions>
        </Dialog>
      ) : null}

      {!cameraActive && !capturedPhoto && !recordedVideo ? (
        ""
      ) : !cameraActive && (capturedPhoto || recordedVideo) ? (
        ""
      ) : (
        <div
          className="jelscButtonContainer"
          style={{
            flexDirection: isPortrait ? "row" : "column",
            width: isPortrait ? "100%" : 100,
            right: isPortrait ? undefined : 10,
          }}
        >
          {getStillsEnabled(eventData.socialSubmissionSettings) && (
            <Button
              variant="contained"
              onClick={handlePhotoClick}
              style={{
                backgroundColor: "#485164",
                color: "white",
                fontWeight: "900",
                marginBottom: isPortrait ? 0 : "24px",
              }}
              disabled={recording}
            >
              <img
                src={send_camera}
                alt="send_camera"
                className="captureButton"
              />
              {isPortrait ? "Capture" : ""}
            </Button>
          )}

          {getRecordingEnabled(eventData.socialSubmissionSettings) && (
            <Button
              variant="contained"
              onClick={handleRecordVideoClick}
              style={{
                backgroundColor: recording ? "#ff00009c" : "#485164",
                color: "white",
                fontWeight: "900",
              }}
            >
              <img
                src={record_icon}
                alt="record_icon"
                className="captureButton"
              />
              {isPortrait ? (recording ? "Stop" : "Record") : ""}
            </Button>
          )}

          {getGalleryEnabled(eventData.socialSubmissionSettings) && (
            <Button
              variant="contained"
              onClick={openGallery}
              style={{
                backgroundColor: "#485164",
                color: "white",
                fontWeight: "900",
                marginBottom: isPortrait ? 0 : "24px",
              }}
              disabled={recording}
            >
              <input
                type="file"
                accept="image/*, video/mp4"
                capture="filesystem"
                multiple={false}
                onChange={handleImageChange}
                ref={inputRef}
                style={{ display: "none" }}
              />
              <AddPhotoAlternate className="captureButton" />
              {isPortrait ? "Import" : ""}
            </Button>
          )}
        </div>
      )}
    </div>
  );
}
