import { useState, useEffect } from "react";
import { Button, TextField, IconButton } from "@mui/material";
import { Wallpaper } from "@mui/icons-material";
const Option = ({
  answer,
  index,
  handleRemoveOption,
  handleAnswerChange,
  formErrors,
}) => {
  const [optionImage, setOptionImage] = useState(answer.optionImage || null);
  const [optionText, setOptionText] = useState(answer.optionText || null);
  useEffect(() => {
    handleAnswerChange(index, optionText, optionImage);
  }, [optionImage, optionText]);
  const inputId = answer._id || answer.keyId;
  return (
    <div
      style={{
        display: "flex",
        marginBottom: "8px",
        width: "100%",
        alignItems: "center",
        backgroundColor: "rgb(71 71 71 / 19%)",
      }}
    >
      <div style={{ flex: 1 }}>
        <TextField
          value={answer.optionText || ""}
          onChange={(event) => setOptionText(event.target.value)}
          variant="outlined"
          size="small"
          fullWidth
          error={!!formErrors.answers}
        />
        {optionImage ? (
          <>
            <img
              src={optionImage}
              alt={optionText}
              style={{
                width: "auto",
                maxHeight: "150px",
                margin: "10px 0",
                objectFit: "cover",
              }}
            />
            <Button
              fullWidth
              variant="outlined"
              onClick={() => {
                setOptionImage(null);
              }}
            >
              Remove Image
            </Button>
          </>
        ) : (
          <>
            <div>
              <label htmlFor={inputId}>Upload Image:</label>
              <input
                type="file"
                id={inputId}
                value={optionImage}
                onChange={(event) => {
                  const file = event.target.files[0];
                  if (file) {
                    const reader = new FileReader();
                    reader.onloadend = () => {
                      const newImage = reader.result;
                      setOptionImage(newImage);
                    };
                    reader.readAsDataURL(file);
                  }
                }}
                accept="image/*"
                style={{ display: "none" }}
              />
              <label htmlFor={inputId}>
                <IconButton
                  component="span"
                  color="primary"
                  aria-label="upload"
                  size="large"
                >
                  <Wallpaper />
                </IconButton>
                Upload Image
              </label>
            </div>
          </>
        )}
      </div>
      <Button
        variant="outlined"
        color="secondary"
        onClick={() => handleRemoveOption(inputId)}
        style={{ marginLeft: "8px" }}
      >
        Remove
      </Button>
    </div>
  );
};
export default Option;
