import "./UvCardsParallax.css";
import Card from "./Card";

const UvCardsParallax = ({
  items,
  requestNewData,
  slideDelay,
  quoteCards,
  mediaItemRotateBy,
  mediaItemX,
  setAlternateCard,
  mediaCardScale,
  useCommonCardStyle,
  showQuoteIcon,
  quoteFontSize,
  shuffle,
  font,
}) => {
  const getCardSettings = (index) => {
    if (quoteCards) {
      if (setAlternateCard) {
        return index % 2 === 0 ? quoteCards[0] : quoteCards[1];
      } else if (useCommonCardStyle) {
        return quoteCards[0];
      } else {
        return quoteCards[index];
      }
    }
  };

  return (
    <main className="main bg">
      {items.map((item, i) => {
        return (
          <Card
            key={item?._id}
            i={i}
            cardSettings={getCardSettings(i)}
            data={item}
            requestNewData={requestNewData}
            itemsLength={items.length}
            mediaItemRotateBy={mediaItemRotateBy}
            mediaItemX={mediaItemX}
            mediaCardScale={mediaCardScale}
            showQuoteIcon={showQuoteIcon}
            quoteFontSize={quoteFontSize}
            enableBlur={item?.orientation !== "portrait"}
            shuffle={shuffle}
            slideDelay={slideDelay}
            font={font}
          />
        );
      })}
    </main>
  );
};

export default UvCardsParallax;
