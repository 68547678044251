import { useEffect, useRef, useState } from "react";
import "./FlippingCard.css";
import { motion } from "framer-motion";
import MediaCard from "./MediaCard";
import { LAYOUT_ORIENTATION_MAPPING } from "../../../utils/defaultSettings";

const FlippingCard = ({
  m1,
  m2,
  flipId,
  orientation,
  onRequestNewMeida,
  className,
  settings,
  isGrid = false,
}) => {
  const { gap, borderWidth, borderColor, roundedCorners } = settings;
  const [media1Data, setMedia1Data] = useState(m1);
  const [media2Data, setMedia2Data] = useState(m2);
  const [isFlipped, setIsFlipped] = useState(false);

  const [isImage1Loading, setIsImage1Loading] = useState(true);
  const [isImage2Loading, setIsImage2Loading] = useState(true);

  const flipCardInnerRef = useRef(null);
  const frontRef = useRef(null);
  const backRef = useRef(null);

  const getBorderRadius = () => {
    if (roundedCorners) {
      if (borderWidth > 5) {
        return `${borderWidth * 4}px`;
      } else {
        return "0.85em";
      }
    } else {
      return "0px";
    }
  };
  const [dynamicStyle, setDynamicStyle] = useState({
    border: `${borderWidth}px solid ${borderColor}`,
    borderRadius: getBorderRadius(),
  });

  useEffect(() => {
    setDynamicStyle({
      border: `${borderWidth}px solid ${borderColor}`,
      borderRadius: getBorderRadius(),
    });
  }, [borderWidth, borderColor, roundedCorners]);

  const getBackTurnedCardMediaSetter = () => {
    const elem = flipCardInnerRef.current;
    if (elem) {
      var style = window.getComputedStyle(elem);
      var matrix = new window.WebKitCSSMatrix(style.transform);
      if (Math.sign(matrix.a) === -1) {
        return setMedia1Data;
      } else {
        return setMedia2Data;
      }
    }
  };

  const requestNewMediaCb = (newMeida) => {
    if (newMeida && newMeida.mediaFileUrl) {
      const mediaCardDataSetter = getBackTurnedCardMediaSetter();
      mediaCardDataSetter(newMeida);
    }
  };

  const triggerFlip = () => {
    if (!isImage1Loading && !isImage2Loading) {
      setIsFlipped((pre) => !pre);
      onRequestNewMeida(requestNewMediaCb, orientation);
    } else {
      // TODO: handle this case
      // console.log(`Card1 ${isImage1Loading}, Card2 ${isImage2Loading}`);
    }
    // console.log(window.playedIds.length);
  };
  useEffect(() => {
    if (flipId === m1?._id + m2?._id) {
      triggerFlip();
    }
  }, [m1, m2, flipId]);
  const style = {
    marginTop:
      className === "card-7-6" ||
      className === "card-7-5" ||
      className === "card-10-6" ||
      className === "card-10-5" ||
      className === "card-10-8" ||
      className === "card-10-9"
        ? `calc(-33.4% + ${gap / 2}px)`
        : "",
  };

  const getEnableBlur = (className, orientation, data) => {
    let enable = false;
    const mapItem = className.includes("card-grid")
      ? "portrait"
      : LAYOUT_ORIENTATION_MAPPING().get(className);
    const mediaAspectRatio = data.width / data.height;
    const innerCardAspectRatio = orientation === "portrait" ? 9 / 16 : 16 / 9;
    const diff = mediaAspectRatio.toFixed(2) - innerCardAspectRatio.toFixed(2);
    if (mapItem !== orientation) {
      enable = true;
    } else if (diff !== 0) {
      enable = true;
    }
    return enable;
  };

  return isGrid ? (
    <div className={`flip-card ${className}`} style={style}>
      <div
        className={`flip-card-inner`}
        style={{ height: "auto" }}
        initial={false}
        ref={flipCardInnerRef}
      >
        <div className="flip-card-front" style={dynamicStyle}>
          <MediaCard
            data={media1Data}
            onLoad={() => setIsImage1Loading(false)}
            ref={frontRef}
            enableBlur={getEnableBlur(className, orientation, media1Data)}
            settings={settings}
          />
        </div>
        <div className="flip-card-back" style={dynamicStyle}>
          <MediaCard
            data={media2Data}
            onLoad={() => setIsImage2Loading(false)}
            ref={backRef}
            enableBlur={getEnableBlur(className, orientation, media2Data)}
            settings={settings}
          />
        </div>
      </div>
    </div>
  ) : (
    <div className={`flip-card ${className}`} style={style}>
      <motion.div
        className={`flip-card-inner`}
        style={{ height: "auto" }}
        initial={false}
        animate={{ rotateY: isFlipped ? 180 : 360 }}
        transition={{ duration: 0.6, animationDirection: "normal" }}
        onAnimationComplete={() => {}}
        ref={flipCardInnerRef}
      >
        <div className="flip-card-front" style={dynamicStyle}>
          <MediaCard
            data={media1Data}
            onLoad={() => setIsImage1Loading(false)}
            ref={frontRef}
            enableBlur={getEnableBlur(className, orientation, media1Data)}
            settings={settings}
          />
        </div>
        <div className="flip-card-back" style={dynamicStyle}>
          <MediaCard
            data={media2Data}
            onLoad={() => setIsImage2Loading(false)}
            ref={backRef}
            enableBlur={getEnableBlur(className, orientation, media2Data)}
            settings={settings}
          />
        </div>
      </motion.div>
    </div>
  );
};
export default FlippingCard;
