import { RGBToHex } from "../../utils/utility";
export const detectionThreshold = 100;
export const beatThreshold = 15;
export const baseBackgroundColor = "#000000";
const flashBackgroundTimeoutIds = [];
const twinklingIntervalIds = [];
const strobeIntervalIds = [];
const starsIntervalIds = [];
const flashIntervalIds = [];
let twinklingActive = false;
let strobeActive = false;
let starsActive = false;
let flashActive = false;
export const targetFrequencies = [
  { value: 0, name: "Live Audio" },
  { value: 18000, name: "18kHz" },
  { value: 20000, name: "20kHz" },
  { value: 22000, name: "22kHz" },
  { value: 19000, name: "19kHz" },
  { value: 15000, name: "15kHz" },
];

function getRandomColor() {
  const letters = "0123456789ABCDEF";
  let color = "#";
  for (let i = 0; i < 6; i++) {
    color += letters[Math.floor(Math.random() * 16)];
  }
  return color;
}

export const flashBackground = (targetElement, color) => {
  if (!flashActive) {
    console.log(`Flash: ${color}`);
    flashActive = true;

    // Create a flash effect by changing the background color
    targetElement.style.transition = "background-color 0.1s ease-in-out";
    targetElement.style.backgroundColor = color; // Use the selected flash color

    // After a short duration, reset the background color to black
    const flashTimeout = setTimeout(() => {
      targetElement.style.backgroundColor = "black";
    }, 100); // Adjust the duration as needed (in milliseconds)
    flashBackgroundTimeoutIds.push(flashTimeout);
  }
};
export const stopFlashBackground = (targetElement) => {
  if (flashActive) {
    console.log(`STOP Flash`);
    flashActive = false;
    flashBackgroundTimeoutIds.forEach(clearTimeout);
    flashIntervalIds.forEach(clearInterval);
    stopEffect(targetElement);
  }
};

const twinklingEffect = (targetElement, color) => {
  if (!twinklingActive) {
    console.log(`Twinkle: ${color}`);
    twinklingActive = true;
    const twinklingInterval = setInterval(() => {
      targetElement.style.backgroundColor =
        RGBToHex(targetElement.style.backgroundColor) === color
          ? "#000000"
          : color;
      targetElement.style.color =
        RGBToHex(targetElement.style.color) === color ? "#000000" : color;
    }, 500);
    twinklingIntervalIds.push(twinklingInterval);
  }
};
const stopTwinkleEffect = (targetElement) => {
  if (twinklingActive) {
    console.log(`STOP Twinkle`);
    twinklingActive = false;
    twinklingIntervalIds.forEach(clearInterval);
    stopEffect(targetElement);
  }
};

const strobeEffect = (targetElement, color) => {
  if (!strobeActive) {
    console.log(`Strobe: ${color}`);
    strobeActive = true;
    const strobeInterval = setInterval(() => {
      const randomColor = getRandomColor();
      targetElement.style.backgroundColor = randomColor;
      targetElement.style.color = "white";
    }, 50);
    strobeIntervalIds.push(strobeInterval);
  }
};

const stopStrobeEffect = (targetElement) => {
  if (strobeActive) {
    console.log(`STOP Strobe`);
    strobeActive = false;
    strobeIntervalIds.forEach(clearInterval);
    stopEffect(targetElement);
  }
};

const starsEffect = (targetElement, color) => {
  if (!starsActive) {
    starsActive = true;
    let starsOpacity = 0;
    let starsDirection = 1;
    const starsInterval = setInterval(() => {
      if (starsOpacity >= 0 && starsOpacity <= 100) {
        starsOpacity += 1 * starsDirection;
        targetElement.style.backgroundColor = `rgba(128, 0, 128, ${
          starsOpacity / 100
        })`;
        if (starsOpacity === 100 || starsOpacity === 50) {
          starsDirection *= -1;
        }
      } else {
        starsOpacity = 0;
        starsDirection = 1;
      }
    }, 20);
    starsIntervalIds.push(starsInterval);
  }
};
const stopStarsEffect = (targetElement) => {
  if (starsActive) {
    starsActive = false;
    starsIntervalIds.forEach(clearInterval);
    stopEffect(targetElement);
  }
};
export const stopEffect = (targetElement) => {
  if (targetElement && targetElement.style) {
    targetElement.style.backgroundColor = "rgb(0, 0, 0)";
    targetElement.style.color = "rgb(255, 255, 255)";
  }
};

export const stopAllEffects = (targetElement) => {
  stopFlashBackground(targetElement);
  stopTwinkleEffect(targetElement);
  stopStrobeEffect(targetElement);
  stopStarsEffect(targetElement);
};
export const effects = [
  {
    value: 1,
    name: "Two Tone Split",
  },
  {
    value: 2,
    name: "Single Color Opacity",
  },
  {
    value: 3,
    name: "Two Color Opacity",
  },
  {
    value: 4,
    name: "Random Color Strobe",
  },
];

export const playEffect = (
  intensity,
  threshold,
  effect,
  show,
  targetElement
) => {
  if (intensity >= threshold) {
    effect.start(targetElement, show.color);
  } else {
    effect.stop(targetElement);
  }
};
